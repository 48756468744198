import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Box, Heading, Divider, Button, Text, Modal, VStack, FormControl, Input, InputGroup, InputLeftAddon, Center, HStack, IconButton, Stack, Skeleton, Image, AlertDialog } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import { ImagePicker } from '../../../../Libs/FilePicker';
import Trans from '../../../Components/Trans';
import ScaledImage from '../../../Components/ScaledImage';
import APIAction from '../../../../Actions/APIAction';
import GeneralAction from '../../../../Actions/GeneralAction';
import RequestCacheAction from '../../../../Actions/RequestCacheAction';
import FileFunctions from '../../../../Libs/FileFunctions';
import { v4 as uuidv4 } from 'uuid';

import main from '../../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const CostListItem = (props) => {

    const [imageSource, setImageSource] = useState(null);
    const [openImage, setOpenImage] = useState(false);

    return (
        <Box>
            <Stack
                style={[mainStyle.borderRadius]}
                space={3}
                direction={{
                    base: 'column',
                    md: 'row'
                }}
                justifyContent={{
                    base: 'flex-start',
                    md: 'space-between',
                }}
                alignItems={'flex-start'}
            >
                <Box>
                    <Heading size={'xs'}><Trans>Description</Trans></Heading>
                    <Divider />
                    <Text>{props.value.description}</Text>
                </Box>
                <Box>
                    <Heading size={'xs'}><Trans>Amount</Trans></Heading>
                    <Divider />
                    <Text>{GeneralAction.formatPrice(props.value.amount)}</Text>
                </Box>

                <HStack alignSelf={'stretch'} space={2}>
                    <Box flex={{
                        base: 1,
                        md: Platform.OS === 'web' ? 'auto' : 0
                    }}>
                        <IconButton icon={<Icon color={'#00AAFF'} size={24} name={'alternate-pencil'} />} borderRadius={'full'} colorScheme={'light'} variant={'subtle'} onPress={() => {
                            if (typeof props.onOpen === 'function') {
                                props.onOpen();
                            }
                        }} />
                    </Box>
                    <Box flex={{
                        base: 1,
                        md: Platform.OS === 'web' ? 'auto' : 0
                    }}>
                        <IconButton
                            icon={<Icon color={'#00AAFF'} size={24} name='image' />}
                            borderRadius={'full'}
                            colorScheme={'light'}
                            variant={'subtle'}
                            onPress={() => {
                                const getData = async () => {
                                    let getImage = await FileFunctions.download({ url: props.value.file });
                                    setImageSource(getImage);
                                };
                                getData();
                                setOpenImage(true);
                            }}
                        />
                    </Box>
                </HStack>
            </Stack>
            <AlertDialog isOpen={openImage} onClose={() => setOpenImage(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Body>
                        {imageSource ?
                            <Center>
                                <Image
                                    height={300}
                                    width={300}
                                    resizeMode={'contain'}
                                    source={imageSource}
                                />
                            </Center>
                            :
                            <Center>
                                <Skeleton width={300} height={300} />
                            </Center>
                        }
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
        </Box>
    );
}

const CostForm = (props) => {

    const [showModal, setShowModal] = useState(false),
        [showImagePicker, setShowImagePicker] = useState(false),
        [uploadedImage, setUploadedImage] = useState(null),
        [formData, setFormData] = useState({
            costId: null,
            employeeShiftId: props.employeeShift.id,
            description: null,
            amount: null
        }),
        [invalidFields, setInvalidFields] = useState({
            description: false,
            amount: false
        }),
        [costItems, setCostItems] = useState([]),
        [dialogId, setDialogId] = useState(uuidv4())
        ;

    let descriptionRef = useRef(null),
        amountRef = useRef(null);

    const getData = useCallback(() => {
        const init = async () => {
            //get costs
            let costs = await APIAction.request({
                method: 'GET', url: '/api/employee_costs', cache: false, params: {
                    employeeShiftId: props.employeeShift.id
                }
            });
            costs = costs['hydra:member'];

            //loop costs
            let costJsx = [];
            for (let value of costs) {
                costJsx.push(
                    <CostListItem value={value} onOpen={() => {
                        setUploadedImage(null);
                        setFormData({
                            costId: value.id,
                            employeeShiftId: props.employeeShift.id,
                            description: value.description,
                            amount: value.amount
                        });
                        descriptionRef.current = value.description;
                        amountRef.current = parseFloat(value.amount).toFixed(2);
                        setDialogId(uuidv4());
                        setShowModal(true);

                    }} />
                );
            }
            setCostItems(costJsx);

        };
        init();
    }, [props]);

    useEffect(() => {
        getData();
    }, [props, getData]);

    const submit = async () => {

        let error = false;

        //all fields are valid to start
        let newInvalidFields = {};
        for (let [index, value] of Object.entries(invalidFields)) {
            newInvalidFields[index] = false;
        };

        //check if valid description
        if (null === formData.description) formData.description = '';
        if ((formData.description).trim() === '') {
            error = true;
            await GeneralAction.toast('error', <Trans>Fill in description</Trans>);
            await GeneralAction.sleep(100);
            newInvalidFields = { ...newInvalidFields, ...{ description: true } };
        }

        //check if valid amount
        if (formData.amount < 0.01) {
            error = true;
            await GeneralAction.toast('error', <Trans>Amount is not valid</Trans>);
            await GeneralAction.sleep(100);
            newInvalidFields = { ...newInvalidFields, ...{ amount: true } };
        }

        //check if file is uploaded
        if (null === uploadedImage && null === formData.costId) {
            error = true;
            await GeneralAction.toast('error', <Trans>No receipt included</Trans>);
            await GeneralAction.sleep(100);
        }

        if (false === error) {

            //set url en method
            let url = '/api/employee_costs';
            
            //set body
            let body = {
                ...formData
            };

            //set file if needed
            if (null !== uploadedImage) {
                body = {
                    ...body,
                    file: uploadedImage
                };
            }

            //end form to backend
            let result = await APIAction.request({
                method: 'POST', url: url, body: body, type: 'multipart/form-data'
            });

            //clear cache
            await RequestCacheAction.clear({ url: '/api/employee_costs' });

            //close modal
            setShowModal(false);

            //show msg
            await GeneralAction.toast('success', <Trans>Cost saved</Trans>);

            //renew
            getData();
        }

        setInvalidFields(newInvalidFields);
    };

    return (
        <>
            <Box style={mainStyle.boxItemVertical}>
                <Heading style={mainStyle.mediumTitle}><Trans>Costs</Trans></Heading>
                <Divider my={3} style={mainStyle.dividerStyle} />
                <VStack space={2} divider={<Divider style={[mainStyle.dividerStyle]} />}>
                    {costItems}
                    <Button onPress={() => {
                        setUploadedImage(null);
                        setFormData({
                            costId: null,
                            employeeShiftId: props.employeeShift.id,
                            description: null,
                            amount: null
                        });
                        descriptionRef.current = null;
                        amountRef.current = null;
                        setDialogId(uuidv4());
                        setShowModal(true);
                    }}>
                        <Text color={'#fff'}><Trans>Add</Trans></Text>
                    </Button>
                </VStack>
            </Box>

            {/* Modal */}
            <Modal isOpen={showModal} onClose={() => setShowModal(false)} avoidKeyboard key={dialogId}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text style={[mainStyle.modalTitle]}><Trans>Cost</Trans></Text></Modal.Header>
                    <Modal.Body>
                        <VStack space={2}>
                            <FormControl isRequired>
                                <FormControl.Label><Text><Trans>Description</Trans></Text></FormControl.Label>
                                <Input
                                   type={'text'}
                                   isInvalid={invalidFields.description}
                                   value={descriptionRef.current}
                                   onChangeText={(val) => {
                                    setFormData({ ...formData, ...{ description: val } });
                                    descriptionRef.current = val;
                                   }}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormControl.Label><Text><Trans>Amount</Trans></Text></FormControl.Label>
                                <InputGroup>
                                    <InputLeftAddon children={'€'} />
                                    <Input
                                        type={'text'}
                                        flexGrow={1}
                                        flexShrink={1}
                                        isInvalid={invalidFields.amount}
                                        value={amountRef.current}
                                        onChangeText={(val) => {
                                            let formatted = val.replace(',', '.');
                                            formatted = parseFloat(val);
                                            if (isNaN(formatted)) formatted = 0;
                                            setFormData({ ...formData, ...{ amount: formatted } });
                                            amountRef.current = val;
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl isRequired>
                                <FormControl.Label><Text><Trans>Upload receipt</Trans></Text></FormControl.Label>
                                <VStack space={2}>
                                    {uploadedImage &&
                                        <Center>
                                            <ScaledImage
                                                uri={uploadedImage.uri}
                                                height={200}
                                                style={{
                                                    resizeMode: 'contain'
                                                }}
                                            />
                                        </Center>
                                    }
                                    <Button onPress={() => {
                                        setShowImagePicker(true);
                                    }}>
                                        <Text color={'#fff'}><Trans>Upload</Trans></Text>
                                    </Button>
                                </VStack>
                            </FormControl>
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant={'ghost'} onPress={() => setShowModal(false)}>
                                <Text><Trans>Close</Trans></Text>
                            </Button>
                            <Button onPress={() => submit()}>
                                <Text color={'#fff'}><Trans>Save</Trans></Text>
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <ImagePicker open={showImagePicker} onTrigger={(val) => setShowImagePicker(val)} onChange={(val) => {
                setUploadedImage(val);
                setShowImagePicker(false);
            }} />
        </>
    );
};

export default CostForm;