import {addEvents, removeEvents, checkCalendarPermissions} from '../Libs/Calendar';
import { LocaleConfig } from 'react-native-calendars';
import TranslationAction from './TranslationAction';
import GeneralAction from './GeneralAction';

const CalendarAction = {
    // Function to add jobs to calendar
    addJobs: async (user) => {
        // Check if user has indicated that we may add jobs to their calendar
        if(user && user.employee && user.employee.addJobsToCalendar){
            // Ask permissions
            let permissionsAsked = await checkCalendarPermissions();

            // Add jobs
            if(permissionsAsked){
                // Add upcoming shift
                await addEvents();

                // Remove shifts from calendar where the user has been removed from
                await removeEvents();
            }
        }

    },

    //init calendar, locales, etc
    init: async () => {
        let language = await TranslationAction.getSelectedLanguage();
        let languageCode = language.code;

        //current date
        let today = new Date();

        //month names
        let monthNames = [];
        let monthNamesShort = [];
        let start = new Date(today);
        start.setMonth(0);

        let dateVal = new Date(start.setDate(1));
        for (let i = 0; i < 12; i++) {
            let valueLong = await GeneralAction.formatLocal(dateVal, {
                month: 'long'
            });
            let valueShort = await GeneralAction.formatLocal(dateVal, {
                month: 'short'
            });
            monthNames.push(valueLong);
            monthNamesShort.push(valueShort);
            dateVal = new Date(dateVal.setMonth(dateVal.getMonth() + 1));
        }

        //day names
        let dayNames = [];
        let dayNamesShort = [];
        start = new Date(today);
        let currDay = start.getDay();
        start = new Date(start.setDate(start.getDate() - currDay));

        dateVal = new Date(start);
        for (let i = 0; i < 7; i++) {
            let valueLong = await GeneralAction.formatLocal(dateVal, {
                weekday: 'long'
            });
            let valueShort = await GeneralAction.formatLocal(dateVal, {
                weekday: 'short'
            });
            dayNames.push(valueLong);
            dayNamesShort.push(valueShort);
            dateVal = new Date(dateVal.setDate(dateVal.getDate() + 1));
        }

        //translate
        let todayTrans = await TranslationAction.translate('Today');

        //set calendar config
        LocaleConfig.locales[languageCode] = {
            monthNames: monthNames,
            monthNamesShort: monthNamesShort,
            dayNames: dayNames,
            dayNamesShort: dayNamesShort,
            today: todayTrans
        };

        LocaleConfig.defaultLocale = languageCode;
    }
};

export default CalendarAction;
