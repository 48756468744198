import React from 'react';
import { Box, HStack, HamburgerIcon, IconButton, Menu, Pressable, useTheme, Text, Button, VStack } from 'native-base';
import AppContainer from '../../Components/AppContainer';
import JobSwiper from './JobSwiper';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const JobSwiperScreen = ({ navigation }) => {

    const { colors } = useTheme();

    return (
        <AppContainer>
            <VStack space={2}>
                <Box>
                    <JobSwiper />
                </Box>
                <Button
                    variant={'outline'}
                    borderColor={colors['primary']['600']}
                    onPress={() => navigation.navigate('CardHistory')}
                >
                    <HStack space={1} alignItems={'center'}>
                        <Icon color={colors['primary']['600']} name={'history'} size={16} />
                        <Text color={colors['primary']['600']}><Trans>History</Trans></Text>
                    </HStack>
                </Button>
                <Button
                    variant={'outline'}
                    borderColor={colors['primary']['600']}
                    onPress={() => {
                        navigation.navigate('Availability');

                    }}
                >
                    <HStack space={1} alignItems={'center'}>
                        <Icon color={colors['primary']['600']} name={'calendar'} size={16} />
                        <Text color={colors['primary']['600']}><Trans>Availability</Trans></Text>
                    </HStack>
                </Button>
            </VStack>
        </AppContainer>
    )
};

export default JobSwiperScreen;