import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, Pressable, FormControl } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import APIAction from '../../../Actions/APIAction';
import main from '../../../Assets/Styles/main.json';
import TranslationAction from '../../../Actions/TranslationAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import AutoComplete from '../../../Libs/Autocomplete';
import SkillsAction from '../../../Actions/SkillsAction';
import SkillGroup from './SkillGroup';
import SkillCheckbox from './SkillCheckbox';
import { v4 as uuidv4 } from 'uuid';
import UserAction from '../../../Actions/UserAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Skills = (props) => {
  const navigation = useNavigation();

  const [getTabSelected, setTabSelected] = useState('tab_function');

  let [language, setLanguage] = useState(null);
  let [skillsMenu, setSkillsMenu] = useState([]);
  let [skillsBox, setSKillsBox] = useState([]);
  let [skillsTaxonomy, setSkillsTaxonomy] = useState([]);
  let [employeeSkills, setEmployeeSkills] = useState([]);

  let [categories, setCategories] = useState(null);
  let [firstCategoryLoaded, setFirstCategoryLoaded] = useState(false);
  let [user, setUser] = useState(props.user);
  let [menuIds, setMenuIds] = useState({});

  /**
   * Get suggestions from api
   *
   * @param q
   * @returns {Promise<void>}
   */
  let getSuggestions = async q => {

    if (typeof q !== 'string' || q.length < 3) {
      return [];
    }

    // Call
    let category = getTabSelected.split('_')[1];
    let response = await APIAction.request({
      method: 'get', url: '/api/autocomplete/skills', params: {
        searchTerm: q,
        category: category,
      }
    });

    const suggestions = response
      .map(item => ({
        id: item.id,
        title: item.parentTitle[language.code] + ' - ' + item.title[language.code],
      }));

    return suggestions;
  };

  /**
   * Get the skills of the employee
   *
   * @param skillsEmployee
   */
  let getEmployeeSkills = (skillsEmployee) => {
    let employeeSkillElements = [];
    let selectedIds = [];

    // Loop through skills
    for (const skillKey in skillsEmployee) {
      let employeeSkill = skillsEmployee[skillKey];
      selectedIds.push(employeeSkill.id);

      // Get title
      let title = employeeSkill.title.en;

      if (language && employeeSkill.title.hasOwnProperty(language.code)) {
        title = employeeSkill.title[language.code];
      }

      // Get category details (colors, etc.)
      let parent = SkillsAction.getParent(employeeSkill);
      let color = '';
      let backgroundColor = '';

      if (parent) {
        color = parent.colorCode;
        backgroundColor = parent.backgroundColorCode;
      }

      // Create element
      employeeSkillElements.push(
        <Box key={uuidv4()} >
          <Pressable
            style={[mainStyle.labelTag, { backgroundColor: backgroundColor ? backgroundColor : '#e9f5fb' }]}
            onPress={() => {
              toggleSkill(employeeSkill.id, 'remove');
              resetSkillCategories();
            }}
          >
            <Box>
              <Text style={{
                fontSize: 14,
                fontWeight: '500',
                color: color ? color : '#00aaff',
              }}>{title}</Text>
            </Box>
            <Box style={{ paddingStart: 3 }}>
              <Icon name={'times'} size={12} style={{ color: color ? color : '#00aaff' }} />
            </Box>
          </Pressable>
        </Box>
      );
    }

    // Set skills
    setEmployeeSkills(employeeSkillElements);

    return selectedIds;
  };

  /**
   * Function to handle skills
   *
   * @param skillId
   */
  let handleSkill = (skillId) => {
    let action = 'add';
    let selected = getEmployeeSkills(user.employee.skills);

    if (selected.includes(skillId)) {
      action = 'remove';
    }

    toggleSkill(skillId, action);
  };

  /**
   * Refresh skills (+ small delay to make sure changes are saved through api)
   */
  let resetSkillCategories = (category = null) => {
    setTimeout(function () {
      setSkillsTaxonomy([]);

      if (!category || !category.length) {
        category = getTabSelected;
      }

      if (!category.includes('tab_')) {
        category = 'tab_' + category;
      }

      getSkills(category, menuIds[category], 2);
    }, 1000);
  };

  /**
   * Function to toggle a skill
   *
   * @param skillId
   * @param action
   * @returns {Promise<void>}
   */
  let toggleSkill = async (skillId, action = 'add') => {
    // Add/remove skill
    await SkillsAction.toggleSkill(skillId, user.employee.id, action);

    // Refresh user
    user = await UserAction.getUser();
    setUser(user);
  };

  /**
   * Function to get skills
   *
   * @param tab
   * @param skillId
   * @param lvl
   * @returns {Promise<void>}
   */
  let getSkills = async (tab, skillId, lvl = 1) => {

    let checkboxes = [];
    let skills = await SkillsAction.findSkills(lvl, skillId);

    if ('hydra:member' in skills) {
      skills = skills['hydra:member'];
    }

    let selected = getEmployeeSkills(user.employee.skills);

    for (const key in skills) {
      let skill = skills[key];

      let category = tab.split('_')[1];

      if (skill.parent.keyCode === category) {
        let title = skill.title.en;

        if (language && skill.title.hasOwnProperty(language.code)) {
          title = skill.title[language.code];
        }

        if (skill.children.length === 0) {
          checkboxes.push(
            <SkillCheckbox key={uuidv4()} skill={skill} title={title} selectedSkills={selected} handleSKill={() => {
              handleSkill(skill.id);
              resetSkillCategories(category);
            }} />
          );
        } else {
          checkboxes.push(
            <SkillGroup key={uuidv4()} skill={skill} title={title} selectedSkills={selected} entityId={user.employee.id} />
          );
        }
      }


    }

    setSkillsTaxonomy(checkboxes);


    setTabSelected(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Get language
      setLanguage(await TranslationAction.getSelectedLanguage());

      // Get skills of employee
      if (user.employee && user.employee.skills) {
        getEmployeeSkills(user.employee.skills);
      }

      // Get categories
      await getSkillCategories();
    };

    /**
     * Function to get the skill categories
     *
     * @returns {Promise<void>}
     */
    let getSkillCategories = async () => {
      // Init
      let skills;
      let menu = [];
      let skillsbox = [];
      let count = 0;
      let mainIds = {};

      // Get skill categories
      if (!categories) {
        skills = await SkillsAction.findSkills(1, null, 'EMPLOYEE');
      } else {
        skills = categories;
      }

      if ('hydra:member' in skills) {
        skills = skills['hydra:member'];
        setCategories(skills);
      }

      // Loop through skills
      for (const key in skills) {
        count++;

        // Default values
        let icon = 'check-circle';
        let backgroundColor = '#faeecf';
        let color = '#000';

        const skill = skills[key];
        let tab = 'tab_' + skill.keyCode;

        if (skill.iconClassApp) {
          icon = skill.iconClassApp;
        }

        if (skill.backgroundColorCode) {
          backgroundColor = skill.backgroundColorCode;
        }

        let title = skill.title.en;

        if (language && skill.title.hasOwnProperty(language.code)) {
          title = skill.title[language.code];
        }

        let level = skill.lvl + 1;

        // Add skill category to array
        mainIds[tab] = skill.id;
        menu.push(
          <Pressable key={uuidv4()} onPress={() => getSkills(tab, skill.id, level)}
            style={[mainStyle.tabItem, { backgroundColor: getTabSelected === tab ? backgroundColor : 'white' }]}>
            <Icon name={icon} size={28}
              style={{ color: getTabSelected === tab ? color : 'rgba(0,0,0,0.5)' }} />
          </Pressable>
        );

        // Initial load of first category page with skills
        if (count === 1 && !firstCategoryLoaded) {
          await getSkills(tab, skill.id, level);
          setFirstCategoryLoaded(true);

          // Refresh user
          let currentUser = await UserAction.getUser();
          setUser(currentUser);
        }

        // Add an element to add skills of a certain category
        skillsbox.push(
          <Box key={uuidv4()}>
            {getTabSelected === tab &&
              <Box>
                <FormControl style={{ marginBottom: 10, zIndex: 999 }}>
                  <FormControl.Label>{title}</FormControl.Label>
                </FormControl>
                {skillsTaxonomy}
              </Box>
            }
          </Box>
        );
      }

      setSkillsMenu(menu);
      setSKillsBox(skillsbox);
      setMenuIds(mainIds);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTabSelected, language, navigation, skillsTaxonomy, user]);

  return (
    <>
      <Box style={mainStyle.boxItemVertical}>
        <Box style={{ width: '100%' }}>
          <Heading style={mainStyle.mediumTitle}>Skills:</Heading>

          <Box style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
            {employeeSkills}
          </Box>
        </Box>
      </Box>
      <Box style={mainStyle.boxItemVertical}>
        <Box style={{ width: '100%' }}>
          <Heading style={mainStyle.mediumTitle}>Add skills:</Heading>

          <Box style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }}>

            {skillsMenu}
          </Box>

          <AutoComplete
            clearOnSelect
            onChange={getSuggestions}
            onSelected={item => {
              handleSkill(item.id);
              resetSkillCategories();
            }}
          />

          <Box>
            {skillsBox}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Skills;
