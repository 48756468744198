import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import {Box, Heading, Pressable, Skeleton, Text} from 'native-base';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import main from '../../../Assets/Styles/main.json';
import AppContainer from '../../Components/AppContainer';
import APIAction from '../../../Actions/APIAction';
import Job from './Components/Job';
import { v4 as uuidv4 } from 'uuid';
import Trans from '../../Components/Trans';


const mainStyle = StyleSheet.create(main);


const PendingJobScreen = () => {

  // Init
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  let [jobElements, setJobElements] = useState([
    <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (isFocused) {
        // Get jobs
        let jobs = await APIAction.request({method: 'get', url: '/api/get/upcoming/employee_shifts'});

        if ('hydra:member' in jobs) {
          jobs = jobs['hydra:member'];
        }

        let elements = [];

        // Loop through jobs
        for (const key in jobs) {
          const job = jobs[key];

          // Add element to array
          elements.push(
              <Pressable
                  sytle={{ paddingLeft: 10 }}
                  onPress={() => {
                    navigation.navigate('Preview', {
                      id: job.id,
                    });
                  }}
              >
                <Job key={uuidv4()} job={job} />
              </Pressable>
          );
        }

        setJobElements(elements);
      }
    };

    // Fetch data
    fetchData();
  }, [isFocused, navigation]);

  // Return view
  return (
    <AppContainer>
      <Box style={mainStyle.pageTitleWrapper}>
        <Box style={{ flexGrow: 1 }}>
          <Heading style={mainStyle.pageTitle}>Upcoming jobs</Heading>
        </Box>
      </Box>

      {/* Default message */}
      {jobElements.length === 0 &&
          <>
            <Box key={uuidv4()} style={mainStyle.boxItemVertical}>
              <Box style={{width: '100%'}}>
                <Text style={mainStyle.infoText}>
                  <Trans>At the moment there are no upcoming jobs.</Trans>
                </Text>
              </Box>
            </Box>
          </>
      }

      {jobElements}

    </AppContainer>
  );
};

export default PendingJobScreen;
