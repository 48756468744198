const SecureStorage = {
    setCredentials: async (username, password) => {
        return true;
    },

    getCredentials: async () => {
        return false;
    },

    clearCredentials: async () => {
        return true;
    }
};

export default SecureStorage;