import { isValidIBAN, isValidBIC } from 'ibantools';

const FormAction = {
    validateEmail: (email) => {
        const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return res.test(String(email).toLowerCase());
    },
    validatePhone: (phone) => {
        var res = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return res.test(phone);
    },
    validateIban: (iban) => {
        iban += '';
        return isValidIBAN(iban.replace(/\s+/g, ''));
    },
    validateBic: (bic) => {
        bic += '';
        return isValidBIC(bic.replace(/\s+/g, ''));
    },
    vaildateRRN: (n) => {
        n += '';
        n = n.replace(/\D/g,'');
    
        // RR numbers need to be 11 chars long
        if (n.length != 11)
            return false;

        var checkDigit = n.substr(n.length - 2, 2);
        var modFunction = function(nr) { return 97 - (nr % 97); };
        var nrToCheck = parseInt(n.substr(0, 9));
        
        // first check without 2
        if (modFunction(nrToCheck) == checkDigit)
            return true;

        // then check with 2 appended for y2k+ births
        nrToCheck = parseInt('2' + n.substr(0, 9));

        return (modFunction(nrToCheck) == checkDigit);
    },
    validateNumber: (number) => {
        var res = /^[0-9]*$/;
        return res.test(number);
    },
    validateEmpty: (input) => {
        if (null === input) input = '';
        input = input + '';
        input = input.trim();
        return input !== '';
    }
};

export default FormAction;