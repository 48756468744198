import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { AlertDialog, Box, Button, HStack, Stack, Text, useTheme, FormControl } from 'native-base';
import Trans from '../../Components/Trans';
import APIAction from '../../../Actions/APIAction';
import GeneralAction from '../../../Actions/GeneralAction';
import { NumberInput } from '../../../Libs/CustomInputs';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import main from '../../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create(main);

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const TimeClock = (props) => {

    const
        { colors } = useTheme(),
        screenWidth = Dimensions.get('window').width,
        firstLoad = useRef(true),
        [showClock, setShowClock] = useState(false),
        [clockInEnabled, setClockInEnabled] = useState(true),
        [clockOutEnabled, setClockOutEnabled] = useState(true),
        [confirmClockIn, setConfirmClockIn] = useState(false),
        [confirmClockOut, setConfirmClockOut] = useState(false),
        breakDuration = useRef(0),
        [clockOutWait, setClockOutWait] = useState(false)
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            //now
            let now = new Date();

            //job start
            let jobStart = new Date(props.job.shift.startOn);
            jobStart.setHours(jobStart.getHours() - 2);

            //job end
            let jobEnd = new Date(props.job.shift.endOn);
            jobEnd.setHours(jobEnd.getHours() + 12);

            if (jobEnd.getTime() - jobStart.getTime() > 86400000) {
                jobEnd = new Date(jobStart.getTime() + 86400000);
            }

            //check if we are in the time frame
            if (now >= jobStart && now <= jobEnd) {
                setShowClock(true);
            }
        };
        init();
    }, [props.job, setShowClock]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad]);

    const clockIn = async () => {
        //disable clock in
        setClockInEnabled(false);

        //clock in
        let clockInResult = await APIAction.request({
            method: 'PATCH',
            url: `/api/employee_shift_clock/${props.job.id}`,
            body: {
                clockedInAt: new Date(),
            }
        });

        //check result
        if (clockInResult) {
            //show toast
            GeneralAction.toast('success', <Trans>You are clocked in</Trans>);
            await GeneralAction.sleep(1000);
            props.onReload();
        }
    };

    const clockOut = async () => {
        //disable clock out
        setClockOutEnabled(false);

        //clock out
        let clockOutResult = await APIAction.request({
            method: 'PATCH',
            url: `/api/employee_shift_clock/${props.job.id}`,
            body: {
                clockedOutAt: new Date(),
                breakDuration: breakDuration.current,
            }
        });

        //check result
        if (clockOutResult) {
            //show toast
            GeneralAction.toast('success', <Trans>You are clocked out</Trans>);
            await GeneralAction.sleep(1000);
            props.onReload();
        }
    };

    if (showClock) {
        return (
            <>
                <Box>
                    <HStack
                        flexGrow={1}
                        flexShrink={1}
                        space={2}
                        alignItems={'center'}
                        justifyContent={!props.job.clockedInAt ? 'flex-end' : 'space-between'}
                    >
                        {props.job.clockedInAt &&
                            <>
                                {props.job.clockedOutAt &&
                                    <Box style={{ flex: 1, alignItems: 'center' }}>
                                        <Box
                                            style={
                                                screenWidth < 768 ?
                                                    { backgroundColor: '#dedede', borderRadius: 5, flexDirection: 'row', justifyContent: 'center', paddingVertical: 7, gap: 10, marginTop: -10, marginBottom: 15, borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '80%' } :
                                                    { backgroundColor: '#dedede', borderRadius: 5, justifyContent: 'center', paddingHorizontal: 10, height: 80, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, marginTop: -5 }}
                                        >
                                            <HStack space={1} alignItems={'center'}>
                                                <Icon color={'grey'} name={'hourglass-start'} size={16} />
                                                <Text color={'grey'}>
                                                    {GeneralAction.formatTime(props.job.clockedOutAt)}
                                                </Text>
                                            </HStack>
                                            {screenWidth < 768 &&
                                                <Text color={'grey'}>-</Text>}
                                            <HStack space={1} alignItems={'center'}>
                                                <Icon color={'grey'} name={'hourglass-end'} size={16} />
                                                <Text color={'grey'}>
                                                    {GeneralAction.formatTime(props.job.clockedInAt)}
                                                </Text>
                                            </HStack>
                                        </Box>
                                    </Box>
                                }
                                {!props.job.clockedOutAt &&
                                    <Box style={{ flex: 1, alignItems: 'center' }}>
                                        <Button
                                            colorScheme={'danger'}
                                            onPress={() => setConfirmClockOut(true)}
                                            isDisabled={!clockOutEnabled}
                                            isLoading={!clockOutEnabled}
                                            style={
                                                screenWidth < 768 ?
                                                    { marginTop: -10, marginBottom: 15, borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '80%' } :
                                                    { height: 80, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, marginTop: -5 }}
                                        >
                                            <Stack
                                                direction={{
                                                    md: 'column',
                                                    base: 'row'
                                                }}
                                                space={{
                                                    md: 0,
                                                    base: 2
                                                }}
                                                alignItems={'center'}
                                            >
                                                <HStack space={1} alignItems={'center'}>
                                                    <Icon name={'stopwatch'} size={20} color={'#fff'} />
                                                    <Text color={'#fff'}>
                                                        <Trans>Stop clock</Trans>
                                                    </Text>
                                                </HStack>
                                                {props.job.clockedInAt &&
                                                    <HStack style={{ backgroundColor: colors['danger']['300'], color: colors['danger']['600'], borderRadius: 5, paddingHorizontal: 7, paddingVertical: 3, marginTop: screenWidth < 768 ? 0 : 5 }}>
                                                        {/* <Icon color={'white'} name={'business-time'} size={11} style={{marginRight: 7}} /> */}

                                                        <Text color={'white'} style={{ fontSize: 11 }}><Trans>Started at </Trans>{GeneralAction.formatTime(props.job.clockedInAt)}</Text>
                                                    </HStack>}
                                            </Stack>
                                        </Button>
                                    </Box>
                                }
                            </>
                        }
                        {!props.job.clockedInAt &&
                            <Box style={{ flex: 1, alignItems: 'center' }}>
                                <Button
                                    colorScheme={'success'}
                                    onPress={() => setConfirmClockIn(true)}
                                    isDisabled={!clockInEnabled}
                                    isLoading={!clockInEnabled}
                                    style={
                                        screenWidth < 768 ?
                                            { marginTop: -10, marginBottom: 15, borderTopLeftRadius: 0, borderTopRightRadius: 0, width: '80%' } :
                                            { height: 80, borderBottomLeftRadius: 0, borderTopLeftRadius: 0, marginTop: -5 }}
                                >
                                    <HStack space={1} alignItems={'center'}>
                                        <Icon name={'stopwatch'} size={20} color={'#fff'} />
                                        <Text color={'#fff'}>
                                            <Trans>Start clock</Trans>
                                        </Text>
                                    </HStack>
                                </Button>
                            </Box>
                        }
                    </HStack>
                </Box>
                <AlertDialog isOpen={confirmClockIn || confirmClockOut} onClose={() => {
                    setConfirmClockIn(false);
                    setConfirmClockOut(false);
                }}>
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton />
                        <AlertDialog.Header><Text style={[mainStyle.modalTitle]}><Trans>Confirm</Trans></Text></AlertDialog.Header>
                        <AlertDialog.Body>
                            {confirmClockIn &&
                                <Text><Trans>Start clock</Trans></Text>
                            }
                            {confirmClockOut &&
                                <FormControl>
                                    <FormControl.Label><Text><Trans>Break (minutes)</Trans></Text></FormControl.Label>
                                    <NumberInput
                                        min={0}
                                        max={300}
                                        value={breakDuration.current}
                                        hideButtons={true}
                                        onChange={(value) => {
                                            breakDuration.current = value;
                                        }}
                                    />
                                </FormControl>
                            }
                        </AlertDialog.Body>
                        <AlertDialog.Footer>
                            <Button.Group space={2}>
                                <Button variant={'ghost'} onPress={() => {
                                    setConfirmClockIn(false);
                                    setConfirmClockOut(false);
                                }}>
                                    <Text><Trans>No</Trans></Text>
                                </Button>
                                {confirmClockIn &&
                                    <Button colorScheme={'success'}
                                        onPress={() => {
                                            setConfirmClockIn(false);
                                            clockIn();
                                        }}
                                    >
                                        <Text color={'#fff'}><Trans>Yes</Trans></Text>
                                    </Button>
                                }
                                {confirmClockOut &&
                                    <Button colorScheme={'danger'}
                                        isLoading={clockOutWait}
                                        isDisabled={clockOutWait}
                                        onPress={() => {
                                            setClockOutWait(true);
                                            setTimeout(() => {
                                                setConfirmClockOut(false);
                                                clockOut();
                                                setClockOutWait(false);
                                            }, 1000);                                          
                                        }}
                                    >
                                        <Text color={'#fff'}><Trans>Yes</Trans></Text>
                                    </Button>
                                }
                            </Button.Group>
                        </AlertDialog.Footer>
                    </AlertDialog.Content>
                </AlertDialog>
            </>
        );
    }
    return <></>;
};

export default TimeClock;