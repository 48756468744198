import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, Platform, InteractionManager } from 'react-native';
import { Box, HStack, ScrollView, Spinner } from 'native-base';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useKeyboard } from './KeyboardHook';
import main from '../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create(main);

const AppContainer = (props) => {

    let background = {
        linearGradient: {
            colors: ['#deeff8', '#fff'],
        },
    };

    let headerProps = {};
    if ('headerColor' in props) {
        headerProps['backgroundColor'] = props.headerColor;
    }

    let footerProps = {};
    if ('footerColor' in props) {
        footerProps['backgroundColor'] = props.footerColor;
    }

    if ('noGradient' in props) {
        background = '#deeff8';
    }

    const keyboardHeight = useKeyboard();

    const [animationsDone, setAnimationsDone] = useState(false);

    let scrollRef = React.useRef();
    if (props.scrollRef) {
        scrollRef = props.scrollRef;
    }

    useEffect(() => {
        setAnimationsDone(false);
        setTimeout(() => {
            InteractionManager.runAfterInteractions(() => {
                setAnimationsDone(true);
            });
        }, 0);
    }, []);

    return (
        <Box flexGrow={1} flexShrink={1}>
            <Box bg={background}
                style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 1 }}
            >
                {animationsDone ?
                    <>
                        <Box {...headerProps}>
                            <SafeAreaView>
                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Box style={{ width: '100%', maxWidth: 1024 }}>
                                        {props.header}
                                    </Box>
                                </Box>
                            </SafeAreaView>
                        </Box>
                        <SafeAreaView style={{ flexGrow: 1, flexShrink: 1, display: 'flex', flexDirection: 'column' }}>
                            {props.noScroll ?
                                <Box style={{ flexGrow: 1, flexShrink: 1 }}>
                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexGrow: 1, flexShrink: 1 }}>
                                        <Box style={mainStyle.contentWrapper}>
                                            {props.children}
                                        </Box>
                                    </Box>
                                </Box> :
                                <ScrollView
                                    ref={scrollRef}
                                    style={{ flexGrow: 1, flexShrink: 1 }}
                                    contentContainerStyle={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                                    alwaysBounceHorizontal={false}
                                    alwaysBounceVertical={false}
                                    automaticallyAdjustKeyboardInsets={true}
                                >
                                    <Box style={mainStyle.contentWrapper}>
                                        {props.children}
                                    </Box>
                                </ScrollView>
                            }
                        </SafeAreaView>
                        <Box {...footerProps}>
                            <SafeAreaView>
                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Box style={{ width: '100%', maxWidth: 1024 }}>
                                        {props.footer}
                                    </Box>
                                </Box>
                            </SafeAreaView>
                        </Box>
                    </> :
                    <HStack
                        h={'100%'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Spinner size={'lg'} />
                    </HStack>
                }
            </Box>

            {
                (keyboardHeight === 0 || Platform.OS === 'ios') &&
                <Box style={{ height: props.tabBarHeight }} />
            }

        </Box>
    );
}

const WithTabBar = (props) => {
    const tabBarHeight = useBottomTabBarHeight();
    return <AppContainer {...props} tabBarHeight={tabBarHeight} />;
}

const Wrapped = (props) => {

    if ('noTabBar' in props) {
        return <AppContainer {...props} tabBarHeight={0} />;
    }
    return <WithTabBar {...props} />;
}

export default Wrapped;