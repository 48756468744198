import React, { useRef, useEffect, useState } from 'react';
import { Box, Badge, useTheme, Text, HStack, VStack, Stack, Divider, Heading, Skeleton, Button, AlertDialog } from 'native-base';
import GeneralAction from '../../../Actions/GeneralAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import Trans from '../../Components/Trans';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import APIAction from '../../../Actions/APIAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const JobHistoryItem = (props) => {

    const
        { colors } = useTheme(),
        [creationDate, setCreationDate] = useState(null),
        [addressFirstLine, setAddressFirstLine] = useState(null),
        [addressSecondLine, setAddressSecondLine] = useState(null),
        reasonModal = useRef(null),
        [reasonModalOpen, setReasonModalOpen] = useState(false),
        undoModal = useRef(null),
        [undoModalOpen, setUndoModalOpen] = useState(false)
        ;

    const onLoad = useEffect(() => {
        const init = async () => {

            let dateObj = new Date(props.data.createdAt);
            let dateString = await GeneralAction.formatLocal(dateObj, {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: '2-digit',
                hour: 'numeric',
                minute: 'numeric'
            });
            setCreationDate(dateString);

            //set address
            let endAddress = null;
            if (props.data.jobPromo.shift.address) {
                endAddress = props.data.jobPromo.shift.address;
            } else if (props.data.jobPromo.shift.project.address) {
                endAddress = props.data.jobPromo.shift.project.address;
            } else if (props.data.jobPromo.shift.project.client.addresses.length > 0) {
                for (let clientAddress of props.data.jobPromo.shift.project.client.addresses) {
                    if (clientAddress.enabled) {
                        endAddress = clientAddress;
                        break;
                    }
                }
            }

            //format address
            if (endAddress) {
                let textAddress = `${endAddress.street} ${endAddress.streetNumber}, ${endAddress.zip} ${endAddress.place}, `;
                let country = await GeneralAction.getCountry(endAddress.country);
                textAddress += country;
                setAddressFirstLine(`${endAddress.street} ${endAddress.streetNumber},`);
                setAddressSecondLine(`${endAddress.zip} ${endAddress.place}, ${country}`);
            }
            else {
                setAddressFirstLine(false);
                setAddressSecondLine(false);
            }

        }
        init();
    }, [props.data, setCreationDate, setAddressFirstLine, setAddressSecondLine]);

    //when user wants to undo reaction
    const onUndo = async () => {
        let result = await APIAction.request({
            method: 'GET',
            url: `/api/job_promos/remove/${props.data.jobPromo.id}`
        });

        if (result.status === 'OK') {
            await GeneralAction.toast('success', <Trans>Your reaction was updated</Trans>);
            props.onRefresh();
        } else {
            await GeneralAction.toast('danger', <Trans>Could not update</Trans>);
        }
    }

    return (
        <>
            <Box
                style={{
                    borderRadius: 12,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    borderColor: props.data['@type'] === 'JobPromoAccepted' && !(!props.isPlanned && props.data.denied === true) ? colors['success']['500'] : colors['danger']['500'],
                    borderWidth: 1,
                    borderStyle: 'solid',
                    padding: 20
                }}
            >
                <HStack
                    space={2}
                    alignItems={'center'}
                >
                    <Box flexGrow={1} flexShrink={1}>
                        <VStack space={2}>
                            <Box>
                                <Stack
                                    space={2}
                                    direction={{
                                        base: 'column',
                                        md: 'row'
                                    }}
                                    justifyContent={'space-between'}
                                >
                                    <HStack alignItems={'center'}>
                                        {props.data['@type'] === 'JobPromoAccepted' && !(!props.isPlanned && props.data.denied === true)  ?
                                            <Icon color={colors['success']['600']} name={'check-circle'} size={30} style={{marginRight: 5}} /> :
                                            <Icon color={colors['danger']['600']} name={'times-circle'} size={30} style={{marginRight: 5}} />
                                        }
                                        {props.data['@type'] === 'JobPromoAccepted' && !(!props.isPlanned && props.data.denied === true) &&
                                            <Text color={colors['success']['600']} style={{fontSize: 18, fontWeight: 600}}><Trans>Applied</Trans></Text> 
                                        }
                                        {props.data['@type'] !== 'JobPromoAccepted' && !(!props.isPlanned && props.data.denied === true) &&
                                            <Text color={colors['danger']['600']} style={{fontSize: 18, fontWeight: 600}}><Trans>Uninterested</Trans></Text>
                                        }
                                        {!props.isPlanned && props.data.denied === true &&
                                            <Text color={colors['danger']['600']} style={{fontSize: 18, fontWeight: 600}}><Trans>Denied</Trans></Text>
                                        }
                                    </HStack>
                                    <HStack flexWrap={'wrap'}>
                                        {/* <Text
                                            style={[mainStyle.timeLabel, {marginLeft: 5, marginBottom: 5}]}
                                            variant={'subtle'}
                                            colorScheme={'info'}
                                        >
                                            <Icon name={'pointer--hand-'} size={15} style={{marginRight: 5}} />
                                            {creationDate}
                                        </Text> */}
                                        {props.data.jobPromo.shift.projectStartOn &&
                                        <Text
                                            style={[mainStyle.timeLabel, {marginLeft: 5, marginBottom: 5}]}
                                            variant={'subtle'}
                                            colorScheme={'secondary'}
                                        >
                                            <Icon name={'car'} size={15} style={{marginRight: 5}} />
                                            {GeneralAction.formatTime(props.data.jobPromo.shift.projectStartOn)}
                                        </Text>}
                                        <Text
                                            style={[mainStyle.timeLabel, {marginLeft: 5, marginBottom: 5}]}
                                            variant={'subtle'}
                                            colorScheme={'primary'}
                                        >
                                            <Icon name={'clock'} size={15} style={{marginRight: 5}} />
                                            {GeneralAction.formatDate(props.data.jobPromo.shift.startOn, false, true) + ' ' + GeneralAction.formatTime(props.data.jobPromo.shift.startOn) + ' - ' + GeneralAction.formatTime(props.data.jobPromo.shift.endOn)}
                                        </Text>
                                    </HStack>
                                </Stack>
                            </Box>
                            <Divider />
                            <Box>
                                <Stack
                                    space={2}
                                    direction={{
                                        base: 'column',
                                        md: 'row'
                                    }}
                                    alignItems={{
                                        base: 'flex-start',
                                        md: 'center'
                                    }}
                                >
                                    <Box flexGrow={1} flexShrink={1}>
                                        <Text>{props.data.jobPromo.shift.project.client.name}</Text>
                                        <Heading size={'sm'} color={colors['primary']['600']}>{props.data.jobPromo.shift.project.name}</Heading>
                                        <Heading size={'sm'}>{props.data.jobPromo.shift.position.alias ? props.data.jobPromo.shift.position.alias : props.data.jobPromo.shift.position.name}</Heading>
                                        {addressFirstLine === null && addressSecondLine === null &&
                                            <VStack space={2}>
                                                <Skeleton.Text lines={2} />
                                            </VStack>
                                        }

                                        {addressFirstLine && addressSecondLine &&
                                            <Box style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }} >
                                                <Icon name={'map-marker'} size={20} />
                                                <Text style={{ flexWrap: 'nowrap' }}>{addressFirstLine}</Text>
                                                <Text style={{ flexWrap: 'nowrap' }}>{addressSecondLine}</Text>
                                            </Box>
                                        }
                                    </Box>
                                    <Box width={{ base: '100%', md: 'auto'}}>
                                        {
                                            props.isPlanned &&
                                            <Box
                                                borderColor={colors['success']['600']}
                                                borderWidth={1}
                                                borderStyle={'solid'}
                                                borderRadius={4}
                                                padding={2}
                                                alignItems={'center'}
                                            >
                                                <Text color={colors['success']['600']}><Trans>Planned</Trans></Text>
                                            </Box>
                                        }
                                        {
                                            props.isPlanned === false && props.data.denied !== true &&
                                            <HStack width={'100%'} space={2} alignItems={'center'} justifyContent={'center'}>
                                                {(new Date(props.data.jobPromo.shift.startOn) > new Date()) &&
                                                    <Button
                                                        colorScheme={'warning'}
                                                        flexGrow={1}
                                                        onPress={() => setUndoModalOpen(true)}
                                                    >
                                                        <Text color={'#fff'}><Trans>Undo</Trans></Text>
                                                    </Button>
                                                }
                                            </HStack>
                                        }
                                        {
                                            !props.isPlanned && props.data.denied === true &&
                                            <HStack space={2} alignItems={'center'}>
                                                {props.data.reasonDenied &&
                                                    <Button
                                                        colorScheme={'danger'}
                                                        variant={'solid'}
                                                        flexGrow={1}
                                                        onPress={() => setReasonModalOpen(true)}
                                                    >
                                                        <Text color={'#fff'}><Trans>Show info</Trans></Text>
                                                    </Button>
                                                }
                                            </HStack>
                                        }

                                    </Box>
                                </Stack>
                            </Box>
                        </VStack>
                    </Box>
                </HStack>
            </Box>

            {/* Reason Modal */}
            <AlertDialog leastDestructiveRef={reasonModal} isOpen={reasonModalOpen} onClose={() => setReasonModalOpen(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text style={[mainStyle.modalTitle]}><Trans>Info</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        {props.data.reasonDenied}
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button colorScheme={'dark'} variant={'ghost'} onPress={() => setReasonModalOpen(false)} ref={reasonModal}>
                                <Text><Trans>Close</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>

            {/* Reset modal */}
            <AlertDialog leastDestructiveRef={undoModal} isOpen={undoModalOpen} onClose={() => setUndoModalOpen(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text style={[mainStyle.modalTitle]}><Trans>Undo</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <Text><Trans>Do you want to reset your reaction to</Trans> {props.data.jobPromo.shift.position.alias ? props.data.jobPromo.shift.position.alias : props.data.jobPromo.shift.position.name}?</Text>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button colorScheme={'dark'} variant={'ghost'} onPress={() => setUndoModalOpen(false)} ref={undoModal}>
                                <Text><Trans>Close</Trans></Text>
                            </Button>
                            <Button colorScheme={'danger'} variant={'solid'} onPress={() => {
                                setUndoModalOpen(false);
                                onUndo();
                            }}>
                                <Text color={'#fff'}><Trans>Undo</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        </>
    );
};

export default JobHistoryItem;