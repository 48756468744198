import React from 'react';
import { StyleSheet, } from 'react-native';
import { Box, Text, Image, Heading, Button } from 'native-base';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';
import main from '../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create({ ...main });

const GreetingScreen = ({ navigation }) => {
    return (
        <AppContainer
            noGradient
            noTabBar
            footer={
                <Box style={[{ display: 'flex', flexDirection: 'row', margin: 30 }]}>
                    <Box style={{ flex: 1 }}>
                        <Button
                            backgroundColor={'white'}
                            onPress={() => {
                                navigation.navigate('Login');
                            }}
                        >
                            <Text style={{ color: '#000', fontWeight: "600", fontSize: 17, paddingVertical: 10, paddingHorizontal: 10, textAlign: 'center' }}><Trans>Login</Trans></Text>
                        </Button>
                    </Box>
                </Box>
            }
        >
            {/* Header */}
            <Box style={mainStyle.headerJoin}>
                <Image
                    source={require('../../Assets/Images/logo.png')}
                    height={20}
                    width={40}
                    resizeMode={'contain'}
                />
            </Box>

            {/* Content */}
            <Box style={mainStyle.contentWrapperJoin}>
                <Box style={{ justifyContent: 'center', alignItems: 'center' }}>

                    <Box style={{ justifyContent: 'center', height: 300 }}>
                        <Image
                            style={{ resizeMode: 'contain', maxHeight: 300 }}
                            source={require('../../Assets/Images/splash.png')}
                            size={'2xl'}
                        />
                    </Box>

                    <Heading style={{ color: '#000', fontWeight: '700', lineHeight: 36, textAlign: 'center', fontSize: 28 }}><Trans>Welcome to</Trans></Heading>
                    <Heading style={{ color: '#556ee6', fontWeight: '700', lineHeight: 36, textAlign: 'center', fontSize: 28 }}><Trans>Skedle</Trans></Heading>
                    {/*<Text style={{ color: 'rgba(0,0,0,0.5)', fontSize: 16, lineHeight: 24, textAlign: 'center', paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
                        <Trans>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Trans>
                    </Text>*/}
                </Box>
            </Box>
        </AppContainer>
    );
}

export default GreetingScreen;