import React, { useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Image, Heading, Button, FormControl, Input } from 'native-base';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';

import main from '../../Assets/Styles/main.json';

import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';

const mainStyle = StyleSheet.create({ ...main });

const ResetPasswordRequestScreen = ({ navigation }) => {
    // Init
    let [email, setEmail] = useState();

    let emailRef = useRef();
    let [loading, setLoading] = useState(false);

    const resetPasswordRequest = async () => {
            // Disable buttons
            setLoading(true);

            // Send request through api
            let data = {
                email: email,
            };
            await APIAction.request({url: '/api/anonymous/change_password_request', method: 'PATCH', body: data});
            GeneralAction.toast('success', 'We have received your request. Please check your mails to reset your password.');

            // Go back to login
            setLoading(false);
            navigation.replace('Login');
    };

    return (
        <AppContainer
            noGradient
            noTabBar
            footer={
                <Box style={mainStyle.registrationAction}>
                    <Box style={{ width: '100%' }}>
                        <Button
                            style={{ backgroundColor: '#00aaff', borderRadius: 10, width: '100%' }}
                            isLoading={loading}
                            isDisabled={loading}
                            onPress={resetPasswordRequest}
                        >
                            <Text style={{ color: '#fff', fontWeight: '600', fontSize: 17, textAlign: 'center', padding: 10 }}><Trans>Reset password</Trans></Text>
                        </Button>
                    </Box>
                </Box>
            }
        >
            {/* Header */}
            <Box style={mainStyle.headerJoin}>
                <Image
                    source={require('../../Assets/Images/logo.png')}
                    height={20}
                    width={40}
                    resizeMode={'contain'}
                />
            </Box>

            {/* Content */}
            <Box style={mainStyle.contentWrapperJoin}>
                <Heading style={{ color: '#000', fontWeight: '500', lineHeight: 36, textAlign: 'center', fontSize: 28 }}><Trans>Reset password</Trans></Heading>
                <Text><Trans>Fill in your e-mailadres below and you will receive an email to reset your password</Trans></Text>
                <Box style={{ width: '100%', maxWidth: 500 }}>
                    <Box style={{ width: '100%', maxWidth: '100%' }} mt="2">
                        <FormControl isRequired>
                            <FormControl.Label><Text><Trans>Email:</Trans></Text></FormControl.Label>
                            <Input
                                ref={emailRef}
                                type={'text'}
                                variant={'filled'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                autoComplete={'email'}
                                returnKeyType={'next'}
                                keyboardType={'email-address'}
                                onChangeText={(text) => {
                                    setEmail(text.trim().toLocaleLowerCase());
                                }}
                                onSubmitEditing={() => {
                                    resetPasswordRequest();
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </AppContainer>
    );
};

export default ResetPasswordRequestScreen;
