import React, { useState, useEffect, useCallback } from 'react';
import { Image as RNImage } from 'react-native';
import { Image } from 'native-base';

const ScaledImage = (props) => {
    const [source, setSource] = useState({ uri: props.uri });
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    const calcSize = useCallback((imageWidth, imageHeight) => {
        const init = async () => {
            let calcWidth = 0;
            let calcHeight = 0;

            if (props.width && !props.height) {
                calcWidth = props.width;
                calcHeight = imageHeight * (props.width / imageWidth);
            } else if (!props.width && props.height) {
                calcWidth = imageWidth * (props.height / imageHeight);
                calcHeight = props.height;
            } else {
                calcWidth = imageWidth;
                calcHeight = imageHeight;
                setWidth(imageWidth);
                setHeight(imageHeight);
            }

            //check max width, max height
            if (props.maxWidth && !props.maxHeight) {
                if (calcWidth > props.maxWidth) {
                    calcWidth = props.maxWidth;
                    calcHeight = imageHeight * (props.maxWidth / imageWidth);
                }
            } else if (!props.maxWidth && props.maxHeight) {
                if (calcHeight > props.maxHeight) {
                    calcWidth = imageWidth * (props.maxHeight / imageHeight);
                    calcHeight = props.maxHeight;
                }
            } else if (props.maxWidth && props.maxHeight) {
                let widthDiff = (calcWidth - props.maxWidth) / props.maxWidth;
                let heightDiff = (calcHeight - props.maxHeight) / props.maxHeight;
                if (widthDiff > 0 || heightDiff > 0) {
                    if (widthDiff > heightDiff) {
                        calcWidth = props.maxWidth;
                        calcHeight = imageHeight * (props.maxWidth / imageWidth);
                    } else {
                        calcWidth = imageWidth * (props.maxHeight / imageHeight);
                        calcHeight = props.maxHeight;
                    }
                }
            }

            //set values
            setWidth(calcWidth);
            setHeight(calcHeight);
        };
        init();
    }, [props]);

    useEffect(() => {
        if (source.uri !== props.uri) {
            setSource({ uri: props.uri });
        }

        if (source.uri) {
            if (typeof source.uri === 'number') {
                let imgObject = RNImage.resolveAssetSource(source.uri);
                calcSize(imgObject.width, imgObject.height);
            } else {
                Image.getSize(props.uri, (imageWidth, imageHeight) => {
                    calcSize(imageWidth, imageHeight);
                });
            }
        }
    }, [props, source, calcSize]);

    return (
        <Image
            {...props.style}
            source={source && typeof source.uri === 'number' ? source.uri : source}
            style={{ width: width, height: height }}
        />

    );
}

export default ScaledImage;