import APIAction from '../../Actions/APIAction';

const FileFunctions = {

    /*
     * Download file, compatible api
     */
    download: async ({ url, params = null, authenticate = true }) => {

        //get file
        let response = await APIAction.request({ method: 'GET', url: url, params: params, authenticate: authenticate, catchFail: false, fileResponse: true });

        if (response === false) return {};

        //to base64
        let base64 = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });

        return { uri: base64 };
    }
};

export default FileFunctions;