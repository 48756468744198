import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AlertDialog, Box, Center, Spinner, Text, useTheme, Card, VStack, HStack, FormControl, IconButton, Button } from 'native-base';
import { Animated, Pressable, InteractionManager, Platform, Dimensions } from 'react-native';
import { createIconSetFromFontello } from 'react-native-vector-icons';
//import Swiper from 'react-native-deck-swiper';
import JobCard from './JobCard';
import APIAction from '../../../Actions/APIAction';
import Lottie from '../../../Libs/Lottie';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import lottieSuccess from '../../../Assets/Lottie/successfully-done.json';
import lottieError from '../../../Assets/Lottie/no-acces-denied.json';
import lottieParty from '../../../Assets/Lottie/party-confetti.json';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import GeneralAction from '../../../Actions/GeneralAction';
import Trans from '../../Components/Trans';
import TranslationAction from '../../../Actions/TranslationAction';
import DateTimePicker from '../../../Libs/DateTimePicker';
import { v4 as uuid } from 'uuid';
import Swipeable from 'react-native-gesture-handler/Swipeable';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const JobSwiper = () => {

    const
        isFocused = useIsFocused(),
        navigation = useNavigation(),
        { colors } = useTheme(),
        firstLoad = useRef(true),
        [jobs, setJobs] = useState(false),
        [jobIndex, setJobIndex] = useState(0),
        jobIndexRef = useRef(jobIndex),
        [swipedAll, setSwipedAll] = useState(false),
        [wait, setWait] = useState(false),
        [minDate, setMinDate] = useState(null),
        [maxDate, setMaxDate] = useState(null),
        swipeRef = useRef(null),
        pressTs = useRef(0)
        ;

    const onFirstload = useCallback(() => {
        const init = async () => {
            if (
                (minDate === null || new Date(minDate).toString() !== 'Invalid Date') &&
                (maxDate === null || new Date(maxDate).toString() !== 'Invalid Date')
            ) {
                setWait(true);
                // Get jobs
                let apiJobs = await APIAction.request({
                    method: 'GET',
                    url: '/api/job_promos',
                    params: {
                        minDate: minDate,
                        maxDate: maxDate
                    }
                });
                if ('hydra:member' in apiJobs) {
                    apiJobs = apiJobs['hydra:member'];
                    if (apiJobs.length <= jobIndexRef.current) {
                        setJobIndex(0);
                    }
                    setJobs(apiJobs);
                    setWait(false);
                }
            }
        };
        init();
    }, [minDate, maxDate]);

    useEffect(() => {
        jobIndexRef.current = jobIndex;
    }, [jobIndex]);

    const handleOnSwipedRight = (cardIndex) => {
        let init = async () => {
            //wait dialog
            setWait(true);

            let job = jobs[cardIndex];

            //make request
            await APIAction.request({
                method: 'PATCH',
                url: '/api/job_promos/' + job.id + '/accept',
                body: {
                    date: new Date(),
                }
            });

            //reload
            onFirstload();

            //toast
            GeneralAction.toast('success', <Trans>Job accepted</Trans>);

        };
        init();
    }

    const handleOnSwipeLeft = (cardIndex) => {
        let init = async () => {
            //wait dialog
            setWait(true);

            let job = jobs[cardIndex];

            //make request
            await APIAction.request({
                method: 'PATCH',
                url: '/api/job_promos/' + job.id + '/refuse',
                body: {
                    date: new Date(),
                }
            });

            //reload
            onFirstload();

            //toast
            GeneralAction.toast('success', <Trans>Job refused</Trans>);

        };
        init();
    }

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstload();
        } else if (isFocused === false) {
            firstLoad.current = true;
        }
    }, [onFirstload, isFocused]);

    useEffect(() => {
        if (!firstLoad.current) {
            onFirstload();
        }
    }, [maxDate, minDate, onFirstload]);

    const renderRightActions = (progress, dragX) => {
        const trans = dragX.interpolate({
            inputRange: [0, 50, 100, 101],
            outputRange: [0, 0, 0, 1],
        });
        return (
            <Center>
                <Animated.View
                    style={[

                        {
                            transform: [{ translateX: trans }],
                        },
                    ]}>
                    <Lottie source={lottieError} autoPlay={true} loop={true} speed={2} style={{ width: 50, height: 50 }} />
                </Animated.View>
            </Center>
        );
    };

    const renderLeftActions = (progress, dragX) => {
        const trans = dragX.interpolate({
            inputRange: [0, 50, 100, 101],
            outputRange: [0, 0, 0, 1],
        });
        return (
            <Center>
                <Animated.View
                    style={[

                        {
                            transform: [{ translateX: trans }],
                        },
                    ]}>
                    <Lottie source={lottieSuccess} autoPlay={true} loop={true} speed={2} style={{ width: 50, height: 50 }} />
                </Animated.View>
            </Center>
        );
    };

    const jobIndexChange = (direction) => {
        if (direction === 'up') {
            if (jobIndex === 0) {
                setJobIndex(jobs.length - 1);
            } else {
                setJobIndex(jobIndex - 1);
            }
        } else if (direction === 'down') {
            if (jobIndex === jobs.length - 1) {
                setJobIndex(0);
            } else {
                setJobIndex(jobIndex + 1);
            }
        }
    };

    return (
        <VStack space={2}>
            <Box>
                <HStack
                    space={2}
                >
                    <Box flexGrow={1} flexShrink={1}>
                        <FormControl>
                            <FormControl.Label><Text><Trans>From</Trans></Text></FormControl.Label>
                            <HStack space={1}>
                                <Box flexGrow={1} flexShrink={1}>
                                    <DateTimePicker
                                        mode={'date'}
                                        value={minDate}
                                        maximumDate={maxDate}
                                        initValue={new Date(new Date().setHours(0, 0, 0, 0))}
                                        onChange={(val) => {
                                            setJobIndex(0);
                                            setMinDate(val);
                                        }}
                                    />
                                </Box>
                                <IconButton
                                    icon={<Icon name={'times'} style={{ color: colors['secondary']['600'] }} />}
                                    variant={'subtle'}
                                    colorScheme={'secondary'}
                                    onPress={() => {
                                        setJobIndex(0);
                                        setMinDate(null);
                                    }}
                                />
                            </HStack>
                        </FormControl>
                    </Box>
                    <Box flexGrow={1} flexShrink={1}>
                        <FormControl>
                            <FormControl.Label><Text><Trans>Until</Trans></Text></FormControl.Label>
                            <HStack space={1}>
                                <Box flexGrow={1} flexShrink={1}>
                                    <DateTimePicker
                                        mode={'date'}
                                        value={maxDate}
                                        minimumDate={minDate}
                                        initValue={new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 7))}
                                        onChange={(val) => {
                                            setJobIndex(0);
                                            setMaxDate(val);
                                        }}
                                    />
                                </Box>
                                <IconButton
                                    icon={<Icon name={'times'} style={{ color: colors['secondary']['600'] }} />}
                                    variant={'subtle'}
                                    colorScheme={'secondary'}
                                    onPress={() => {
                                        setJobIndex(0);
                                        setMaxDate(null);
                                    }}
                                />
                            </HStack>
                        </FormControl>
                    </Box>
                </HStack>
            </Box>
            <Center>
                <Box
                    width={'100%'}
                >
                    {jobs === false ?
                        <Spinner size={'lg'} />
                        :
                        <>
                            {jobs.length > 0 ?
                                <Box>
                                    
                                    <Swipeable
                                        ref={swipeRef}
                                        renderLeftActions={renderLeftActions}
                                        renderRightActions={renderRightActions}
                                        leftThreshold={200}
                                        rightThreshold={200}
                                        onSwipeableOpen={(direction) => {
                                            setTimeout(() => {
                                                if (direction === 'right') {
                                                    handleOnSwipeLeft(jobIndex);
                                                } else if (direction === 'left') {
                                                    handleOnSwipedRight(jobIndex);
                                                }
                                                swipeRef.current.close();
                                            }, 1000);
                                        }}
                                    >
                                        <VStack space={2} alignItems={'center'}>
                                            <Button
                                                flexGrow={1}
                                                flewShrink={1}
                                                variant={'subtle'}
                                                colorScheme={'secondary'}
                                                style={[{marginBottom: -10, width: 400}, Platform.OS !== 'web' ? {width: Dimensions.get('window').width/100*80} : {}]}
                                                onPress={() => {
                                                    InteractionManager.runAfterInteractions(() => {
                                                        setTimeout(() => jobIndexChange('up'), 100);
                                                    });
                                                }}
                                            >
                                                <Icon size={20} color={colors['secondary']['600']} name={'angle-up'} />
                                            </Button>
                                            <HStack>
                                                <Box flexGrow={1} flexShrink={1}>
                                                    <Pressable
                                                        pressRetentionOffset={5}
                                                        onPressIn={(event) => {
                                                            pressTs.current = new Date().getTime();
                                                        }}
                                                        onPressOut={(event) => {
                                                            let now = new Date().getTime();
                                                            if (Platform.OS === 'web') {
                                                                if (now - pressTs.current < 100) {
                                                                    let job = jobs[jobIndex];
                                                                    navigation.navigate('Apply', { id: job.id });
                                                                }
                                                            }
                                                        }}
                                                        onPress={(event) => {
                                                            if (Platform.OS !== 'web') {
                                                                let job = jobs[jobIndex];
                                                                navigation.navigate('Apply', { id: job.id });
                                                            }
                                                        }}
                                                    >
                                                        <JobCard
                                                            key={uuid()}
                                                            data={jobs[jobIndex]}
                                                            onSwipedRight={() => { handleOnSwipedRight(jobIndex) }}
                                                            onSwipedLeft={() => { handleOnSwipeLeft(jobIndex) }}
                                                        />
                                                    </Pressable>
                                                </Box>
                                            </HStack>
                                            <Button
                                                flexGrow={1}
                                                flexShrink={1}
                                                variant={'subtle'}
                                                colorScheme={'secondary'}
                                                style={[{marginTop: -8, width: 400}, Platform.OS !== 'web' ? {width: Dimensions.get('window').width/100*80} : {}]}
                                                onPress={() => {
                                                    InteractionManager.runAfterInteractions(() => {
                                                        setTimeout(() => jobIndexChange('down'), 100);
                                                    });
                                                }}
                                            >
                                                <Icon size={20} color={colors['secondary']['600']} name={'angle-down'} />
                                            </Button>
                                        </VStack>
                                    </Swipeable>
                                </Box>
                                :
                                <Center>
                                    <Lottie source={lottieParty} autoPlay={true} loop={true} speed={1} style={{ width: 200, height: 200 }} />
                                </Center>
                            }

                            {/* Commented for later use, shows a bar for more info about the swiper and jobs. */}
                            {/*<Card style={{marginBottom: 20, backgroundColor: '#dde3ff'}}>*/}
                            {/*    <Text style={{color: '#5972E7'}}><Icon name="info-circle" size={15} /> This is an example text for more info about the jobs</Text>*/}
                            {/*</Card>*/}
                            {/*jobs.length === 0 || swipedAll ?
                                <Center>
                                    <Lottie source={lottieParty} autoPlay={true} loop={true} speed={1} style={{ width: 200, height: 200 }} />
                                </Center>
                                :
                                <Swiper
                                    infinite={true}
                                    backgroundColor={'transparent'}
                                    verticalSwipe={true}
                                    disableBottomSwipe={false}
                                    disableTopSwipe={false}
                                    goBackToPreviousCardOnSwipeBottom={true}
                                    cardVerticalMargin={0}
                                    cardHorizontalMargin={0}
                                    horizontalThreshold={150}
                                    overlayOpacityHorizontalThreshold={50}
                                    cards={jobs}
                                    stackSize={3}
                                    cardStyle={{ height: 300, width: '100%' }}
                                    containerStyle={{ height: 300, position: 'relative' }}
                                    overlayLabels={{
                                        right: {
                                            element: <Lottie source={lottieSuccess} autoPlay={true} loop={false} speed={2} style={{ width: 50, height: 50 }} />,
                                        },
                                        left: {
                                            element: <Lottie source={lottieError} autoPlay={true} loop={false} speed={2} style={{ width: 50, height: 50 }} />,
                                        }
                                    }}
                                    overlayLabelWrapperStyle={{
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: -50,
                                        marginLeft: 0,
                                    }}
                                    renderCard={(card, index) => {
                                        return (
                                            <JobCard data={card} onSwipedRight={() => { handleOnSwipedRight(index) }} onSwipedLeft={() => { handleOnSwipeLeft(index) }} />
                                        );
                                    }}
                                    onSwipedRight={(cardIndex) => {
                                        handleOnSwipedRight(cardIndex);
                                    }}
                                    onSwipedLeft={(cardIndex) => {
                                        handleOnSwipeLeft(cardIndex);
                                    }}
                                    onSwipedAll={() => {
                                        setSwipedAll(true);
                                    }}
                                    onTapCard={(index) => {
                                        let job = jobs[index];
                                        navigation.navigate('Apply', { id: job.id });
                                    }}
                                >
                                </Swiper>
                                */}
                        </>
                    }
                </Box>
            </Center>
            <AlertDialog isOpen={wait}>
                <AlertDialog.Content>
                    <AlertDialog.Body>
                        <Spinner size={'lg'} />
                        <Center>
                            <Text>
                                <Trans>Please wait...</Trans>
                            </Text>
                        </Center>
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
        </VStack >
    );
};

export default JobSwiper;