import { Model } from '@nozbe/watermelondb';
import { text, relation } from '@nozbe/watermelondb/decorators';

export default class Translation extends Model {
    static table = 'translation';
    static associations = {
        language: { type: 'belongs_to', key: 'language_id' }
    }

    @text('key') key;
    @text('value') value;
    @relation('language', 'language_id') language;
};