import { Model } from '@nozbe/watermelondb';
import { field, text, json, children } from '@nozbe/watermelondb/decorators';

const sanitizeName = json => json;

export default class Language extends Model {
    static table = 'language';
    static associations = {
        translation: { type: 'has_many', foreignKey: 'language_id' }
    }

    @text('code') code;
    @json('name', sanitizeName) name;
    @field('is_selected') isSelected;
    @children('translation') translations;
};