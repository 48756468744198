import { Platform } from 'react-native';
import PoppinsConfig from '../../Assets/Fonts/PoppinsConfig.json';

const MultiFonts = [
    PoppinsConfig
];

const Fonts = [
    'line-awesome',
    'line-awesome-brands'
];

const WebFonts = [
    'AntDesign',
    'Entypo',
    'EvilIcons',
    'Feather',
    'FontAwesome',
    'FontAwesome5_Brands',
    'FontAwesome5_Regular',
    'FontAwesome5_Solid',
    'Fontisto',
    'Foundation',
    'Ionicons',
    'MaterialCommunityIcons',
    'MaterialIcons',
    'Octicons',
    'SimpleLineIcons',
    'Zocial'
];

const FontAction = {
    init: () => {
        if (Platform.OS === 'web') {

            //get elements
            let head = document.getElementsByTagName('head')[0];
            let fontStyles = document.createElement('style');

            //font string
            let fontString = '';
            Fonts.forEach(element => {
                let font = require('../../Assets/Fonts/' + element + '.ttf');
                fontString += '\
          @font-face {\
            font-family: "' + element + '";\
            src: url("' + font + '");\
          }';
            });

            //add web specific fonts
            WebFonts.forEach(element => {
                let font = require('../../Assets/WebFonts/' + element + '.ttf');
                fontString += '\
          @font-face {\
              font-family: "' + element + '";\
              src: url("' + font + '");\
          }';
            });

            //add font families
            MultiFonts.forEach(element => {
                for (let [name, value] of Object.entries(element.types)) {
                    let font = require('../../Assets/Fonts/' + element.familyName + '-' + name + '.ttf');
                    fontString += '\
            @font-face {\
              font-family: "' + element.familyName + '";\
              src: url("' + font + '");\
              font-weight: ' + value.weight + ';\
              font-style: ' + value.style + ';\
            }';
                };
            });

            //set
            fontStyles.innerHTML = fontString;

            //append
            head.appendChild(fontStyles);
        }
    }
};

export default FontAction;