import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { HStack, Box, Text, Heading, Skeleton, Pressable, Input } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import FormAction from '../../../Actions/FormAction';
import APIAction from '../../../Actions/APIAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const Iban = (props) => {

    const
        [editIban, setEditIban] = useState(false),
        [iban, setIban] = useState(null),
        [ibanError, setIbanError] = useState(null)
        ;

    useEffect(() => {
        if (props.user) {
            setIban(props.user.employee.iban);
        }
    }, [props.user]);

    const save = async () => {
        // Validate iban with regex
        let trimmedIban = iban.toUpperCase().replace(/\s/g, '');

        if (FormAction.validateIban(trimmedIban)) {
            setIbanError(null);
            setEditIban(false);

            //send
            await APIAction.request({
                method: 'PATCH',
                url: `/api/employees/${props.user.employee.id}`,
                body: {
                    iban: trimmedIban
                }
            });

            //reload
            if (typeof props.onReload === 'function') {
                props.onReload();
            }

        } else {
            setIbanError(<Trans>Please enter a valid IBAN number</Trans>);
        }
    }

    return (
        <>
            {props.user ?
                <Box style={[mainStyle.boxItem, { marginBottom: 0, flex: 1 }]}>
                    <Box style={{ alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                        <Box style={{ width: '100%' }}>
                            <Box style={{ flexDirection: 'row' }}>
                                <HStack
                                    flexGrow={1}
                                    flexShrink={1}
                                    space={2}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Text style={{ color: 'rgba(0,0,0,0.5)' }}>
                                        <Trans>IBAN</Trans>
                                    </Text>
                                    {/*editIban ?
                                        <Pressable onPress={() => {
                                            save();
                                        }}>
                                            <Icon name={'save'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                        </Pressable>
                                        :
                                        <Pressable onPress={() => {
                                            setEditIban(true);
                                        }}>
                                            <Icon name={'edit'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                        </Pressable>
                                    */}
                                </HStack>
                            </Box>
                            {
                                editIban ?
                                    <>
                                        <Input w={'100%'} defaultValue={iban} onChangeText={(value) => setIban(value)} />
                                        {ibanError !== '' && <Text style={main.textError}>{ibanError}</Text>}
                                    </> :
                                    <Heading style={[mainStyle.mediumTitle, { fontSize: 14 }]}>
                                        {iban ? iban :
                                            <Trans>Not added</Trans>
                                        }
                                    </Heading>
                            }
                        </Box>
                    </Box>
                </Box> :
                <Box flex={1}>
                    <Skeleton h={85} />
                </Box>
            }
        </>
    );
};

export default Iban;