import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Skeleton, Text, Popover, Heading, VStack, Stack, Box, HStack } from 'native-base';
import AppContainer from '../Components/AppContainer';
import { v4 as uuidv4 } from 'uuid';
import APIAction from '../../Actions/APIAction';
import { useIsFocused } from '@react-navigation/native';
import Profile from './Components/Profile';
import Skills from './Components/Skills';
import Trans from '../Components/Trans';
import UserAction from '../../Actions/UserAction';
import Name from './Components/Name';
import PreferenceChecks from './Components/PreferenceChecks';
import ContactInfo from './Components/ContactInfo';
import Iban from './Components/Iban';
import Address from './Components/Address';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const OverviewProfileScreen = ({ navigation }) => {
    /*const isFocused = useIsFocused();*/

    const firstLoad = useRef(true);
    const
        [user, setUser] = useState(null)
        ;

    /*let [profileElement, setProfileElement] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);

    let [skillElement, setSkillElement] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'} />,
    ]);

    useEffect(() => {
        const fetchData = async () => {
            if (isFocused) {
                // Get current user
                let user = await UserAction.getUser();

                setProfileElement(<Profile user={user} />);
                //setSkillElement(<Skills user={user} />);
            }
        };

        // Fetch data
        fetchData();
    }, [isFocused]);
    */

    const reloadUser = useCallback(() => {
        const init = async () => {
            let user = await APIAction.request({
                method: 'GET',
                url: '/api/current/user'
            });
            await UserAction.setUser(user);
            setUser(user);
        };
        init();
    }, []);


    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            reloadUser();
        }
    }, [reloadUser]);



    return (
        <AppContainer>
            <VStack space={2}>
                <Name
                    user={user}
                    onReload={() => {
                        reloadUser();
                    }}
                />
                {/*<PreferenceChecks
                    user={user}
                    onReload={() => {
                        reloadUser();
                    }}
                />*/}
                <ContactInfo
                    user={user}
                    onReload={() => {
                        reloadUser();
                    }}
                />
                <Stack
                    space={2}
                    w={'100%'}
                    direction={{
                        base: 'column',
                        lg: 'row'
                    }}
                    alignItems={{
                        base: 'stretch',
                        lg: 'flex-start'
                    }}
                >
                    <Iban
                        user={user}
                        onReload={() => {
                            reloadUser();
                        }}
                    />
                    <Address
                        user={user}
                        onReload={() => {
                            reloadUser();
                        }}
                    />
                </Stack>
                <Popover
                    placement={'top'}
                    trigger={triggerProps => {
                        return (
                            <Button {...triggerProps} colorScheme={'danger'}>
                                <Text color={'#fff'}><Trans>Log out</Trans></Text>
                            </Button>
                        );
                    }}
                >
                    <Popover.Content w={200}>
                        <Popover.Arrow />
                        <Popover.CloseButton />
                        <Popover.Header><Text><Trans>Log out?</Trans></Text></Popover.Header>
                        <Popover.Body>
                            <Button onPress={() => {
                                const logout = async () => {
                                    await UserAction.logOut();
                                    navigation.reset({
                                        index: 0,
                                        routes: [{
                                            name: 'Public'
                                        }]
                                    });
                                };
                                logout();
                            }}>
                                <Text color={'#fff'}><Trans>Yes</Trans></Text>
                            </Button>
                        </Popover.Body>
                    </Popover.Content>
                </Popover>
            </VStack>




            {/* Content */}
            {/*{profileElement}*/}
            {/* skillElement */}


            {/*<Box style={mainStyle.boxItemVertical}>
        <Box style={{ width: '100%' }}>
          <Heading style={mainStyle.mediumTitle}>Friends who work on this job:</Heading>

          <Pressable
            onPress={() => {
              navigation.navigate('/');
            }}
          >
            <Box style={{ paddingVertical: 5, alignItems: 'center', flexDirection: 'row' }}>
              <Box style={{ width: 60 }}>
                <Image size={50}
                  style={{ borderRadius: 25 }}
                  source={require('../../Assets/Images/user-1.png')}
                />
              </Box>
              <Box style={{ flex: 1 }}>
                <Heading style={{ fontSize: 14, fontWeight: '500' }}>Wim Schiettecat</Heading>
              </Box>
              <Box style={{ width: '8%', paddingStart: 10 }}>
                <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }}  />
              </Box>
            </Box>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate('/');
            }}
          >
            <Box style={{ paddingVertical: 5, alignItems: 'center', flexDirection: 'row' }}>
              <Box style={{ width: 60 }}>
                <Image size={50}
                  style={{ borderRadius: 25 }}
                  source={require('../../Assets/Images/user-2.png')}
                />
              </Box>
              <Box style={{ flex: 1 }}>
                <Heading style={{ fontSize: 14, fontWeight: '500' }}>Vincent Dierickx</Heading>
              </Box>
              <Box style={{ width: '8%', paddingStart: 10 }}>
                <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }}  />
              </Box>
            </Box>
          </Pressable>

        </Box>
      </Box> */}


        </AppContainer>
    );
};

export default OverviewProfileScreen;