import React, { useEffect, useState } from 'react';
import { nl, enGB, fr, de } from 'date-fns/locale';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider, DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { Select } from '../CustomInputs';
import TranslationAction from '../../Actions/TranslationAction';
import GeneralAction from '../../Actions/GeneralAction';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import '../../Assets/Styles/rc-time-picker.css';

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        fontSize: 12,
        '& > input': {
            lineHeight: '1.5em',
            paddingTop: 8.5,
            paddingBottom: 8.5,
            height: '100%',
            width: '100%'
        },
        backgroundColor: '#fff',
        '& fieldset': {
            borderColor: '#d4d4d4'
        },
        '&:hover fieldset': {
            borderColor: '#00aaff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#00aaff'
        },
    },
});

const DateTimePicker = (props) => {

    let mode = 'date';
    if ('mode' in props) mode = props.mode;

    const [locale, setLocale] = useState(null);
    const [value, setValue] = useState(props.value);
    const [showTz, setShowTz] = useState(false);
    const [translations, setTranslations] = useState({
        'Winter time': '',
        'Summer time': '',
        'Timezone': ''
    });

    useEffect(() => {
        const init = async () => {

            setValue(props.value);

            //check if day is 24 hours, if not, is winter/summer time change
            let tsStartDate = new Date(props.value).setHours(0, 0, 0, 0);
            let tsEndDate = new Date(props.value).setHours(23, 59, 59, 999);
            let tsDiff = Math.round((tsEndDate - tsStartDate) / 1000 / 3600);

            //check tomomorrow
            let tsTomorrowStart = new Date((new Date()).setDate(new Date(props.value).getDate() + 1));
            tsTomorrowStart.setHours(0, 0, 0, 0);
            let tsTomorrowEnd = new Date((new Date()).setDate(new Date(props.value).getDate() + 1));
            tsTomorrowEnd.setHours(23, 59, 59, 999);
            let tsTomorrowDiff = Math.round((tsTomorrowEnd - tsTomorrowStart) / 1000 / 3600);

            if (tsDiff !== 24 || tsTomorrowDiff !== 24) {

                let tzList = [new Date(tsStartDate).getTimezoneOffset(), new Date(tsEndDate).getTimezoneOffset()];

                if (tsTomorrowDiff !== 24) {
                    tzList = [new Date(tsTomorrowStart).getTimezoneOffset(), new Date(tsTomorrowEnd).getTimezoneOffset()];
                }

                setShowTz({
                    tzWinter: GeneralAction.tzOffsetToTz(Math.max(...tzList)),
                    tzSummer: GeneralAction.tzOffsetToTz(Math.min(...tzList)),
                    tzCurrent: GeneralAction.tzOffsetToTz(new Date(props.value).getTimezoneOffset())
                });
            } else {
                setShowTz(false);
            }

            //set locale
            let currentLanguage = await TranslationAction.getSelectedLanguage();
            switch (currentLanguage.code) {
                case 'nl':
                    setLocale(nl);
                    break;
                case 'fr':
                    setLocale(fr);
                    break;
                case 'de':
                    setLocale(de);
                    break;
                default:
                    setLocale(enGB);
            }
        }
        init();
    }, [props.value]);

    //translate
    useEffect(() => {
        const init = async () => {
            //set translations
            let transCopy = JSON.parse(JSON.stringify(translations));
            for (let [index, value] of Object.entries(translations)) {
                let translation = await TranslationAction.translate(index);
                transCopy[index] = translation;
            }
            setTranslations(transCopy);
        }
        if (translations.Timezone === '') {
            init();
        }
    }, [translations]);

    //create theme for pickers
    const theme = createTheme({
        typography: {
            fontFamily: 'Poppins, sans-serif',

        },
        palette: {
            primary: {
                main: '#00AAFF'
            }
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                }
            }
        }
    });

    const onChangeTz = (tz) => {
        if (typeof props.onChange === 'function') {
            let strDate = new Date(value).toString();
            let indexDate = strDate.indexOf('GMT');
            strDate = strDate.substring(0, indexDate + 3);
            strDate += tz.replace(':', '');
            props.onChange(new Date(strDate));
        }
    }

    //wait until locale set
    if (null === locale) {
        return <></>;
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                {mode === 'date' &&
                    <DatePicker
                        {...props}
                        maxDate={props.maximumDate}
                        minDate={props.minimumDate}
                        renderInput={(params) => <CssTextField {...params} />}
                    />
                }
                {mode === 'time' &&
                    <>
                        <TimePicker
                            {...props}
                            placeholder="Select Time"
                            showSecond={false}
                            focusOnOpen={true}
                            format="kk:mm"
                            value={moment(props.value)}
                            disabledMinutes={() => { return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 
                                21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38, 
                                39, 40, 41, 42, 43, 44, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 
                                57, 58, 59]}}
                            hideDisabledOptions={true}
                            onChange={(value) => {
                                try {
                                    let date = value.toDate();
                                    if (typeof props.onChange === 'function') {
                                        props.onChange(date);
                                    }
                                } catch (e) {}
                            }}
                            
                        />
                        {showTz &&
                            <Select key={uuidv4()} selectedValue={showTz.tzCurrent} mt={3} placeholder={translations.Timezone} onValueChange={(val) => {
                                onChangeTz(val);
                            }}>
                                <Select.Item label={translations['Winter time']} value={showTz.tzWinter} />
                                <Select.Item label={translations['Summer time']} value={showTz.tzSummer} />
                            </Select>
                        }
                    </>
                }
                {mode === 'datetime' &&
                    <>
                        <MUIDateTimePicker
                            {...props}
                            maxDateTime={props.maximumDate}
                            minDateTime={props.minimumDate}
                            renderInput={(params) => <CssTextField {...params} />}
                        />
                        {showTz &&
                            <Select key={uuidv4()} selectedValue={showTz.tzCurrent} mt={3} placeholder={translations.Timezone} onValueChange={(val) => {
                                onChangeTz(val);
                            }}>
                                <Select.Item label={translations['Winter time']} value={showTz.tzWinter} />
                                <Select.Item label={translations['Summer time']} value={showTz.tzSummer} />
                            </Select>
                        }
                    </>
                }
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default DateTimePicker;