import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, Pressable } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import main from '../../Assets/Styles/main.json';
import AppContainer from '../Components/AppContainer';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const DocumentsScreen = ({ navigation }) => {
  return (
    <AppContainer>
      {/*
      <Box style={mainStyle.pageTitleWrapper}>
        <Box style={{ flexGrow: 1 }}>
          <Heading style={mainStyle.pageTitle}>Documents</Heading>
        </Box>
      </Box>
      */}

      <Box style={mainStyle.pageSubTitleWrapper}>
        <Box style={{ flexGrow: 1 }}>
          <Heading style={mainStyle.pageSubTitle}>Contracts</Heading>
        </Box>
      </Box>
      <Box style={mainStyle.fileItemWrapper}>
        <Pressable
          style={{ width: '100%' }}
          onPress={() => {
            navigation.navigate('Home');
          }}
        >
          <Box style={mainStyle.boxItemCenter}>
            <Box style={{ width: '92%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box style={{ width: 65, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box size={60} style={mainStyle.boxIconYellow}>
                  <Icon name={'alternate-file'} size={32} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                </Box>
              </Box>
              <Box style={{ flexBasis: 'auto', flexGrow: 1, flexShrink: 1 }}>
                <Text style={mainStyle.infoText}>2022-01-04</Text>
                <Heading style={mainStyle.mediumTitle}>Raamovereenkomst</Heading>
              </Box>
            </Box>
            <Box style={{ width: '8%', paddingStart: 10 }}>
              <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
            </Box>
          </Box>
        </Pressable>
      </Box>
      <Box style={mainStyle.fileItemWrapper}>
        <Pressable
          style={{ width: '100%' }}
          onPress={() => {
            navigation.navigate('Home');
          }}
        >
          <Box style={mainStyle.boxItemCenter}>
            <Box style={{ width: '92%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box style={{ width: 65, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box size={60} style={mainStyle.boxIconYellow}>
                  <Icon name={'alternate-file'} size={32} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                </Box>
              </Box>
              <Box style={{ flexBasis: 'auto', flexGrow: 1, flexShrink: 1 }}>
                <Text style={mainStyle.infoText}>2022-06-12</Text>
                <Heading style={mainStyle.mediumTitle}>Weekcontract</Heading>
              </Box>
            </Box>
            <Box style={{ width: '8%', paddingStart: 10 }}>
              <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
            </Box>
          </Box>
        </Pressable>
      </Box>
      <Box style={mainStyle.fileItemWrapper}>
        <Pressable
          style={{ width: '100%' }}
          onPress={() => {
            navigation.navigate('Home');
          }}
        >
          <Box style={mainStyle.boxItemCenter}>
            <Box style={{ width: '92%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box style={{ width: 65, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box size={60} style={mainStyle.boxIconYellow}>
                  <Icon name={'alternate-file'} size={32} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                </Box>
              </Box>
              <Box style={{ flexBasis: 'auto', flexGrow: 1, flexShrink: 1 }}>
                <Text style={mainStyle.infoText}>2022-06-16</Text>
                <Heading style={mainStyle.mediumTitle}>Dagcontract</Heading>
              </Box>
            </Box>
            <Box style={{ width: '8%', paddingStart: 10 }}>
              <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
            </Box>
          </Box>
        </Pressable>
      </Box>
      <Box style={mainStyle.pageSubTitleWrapper}>
        <Box style={{ flexGrow: 1 }}>
          <Heading style={mainStyle.pageSubTitle}>Payslips</Heading>
        </Box>
      </Box>
      <Box style={mainStyle.fileItemWrapper}>
        <Pressable
          style={{ width: '100%' }}
          onPress={() => {
            navigation.navigate('Home');
          }}
        >
          <Box style={mainStyle.boxItemCenter}>
            <Box style={{ width: '92%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box style={{ width: 65, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box size={60} style={mainStyle.boxIconWhite}>
                  <Icon name={'file-invoice-with-us-dollar'} size={32} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
                </Box>
              </Box>
              <Box style={{ flexBasis: 'auto', flexGrow: 1, flexShrink: 1 }}>
                <Text style={mainStyle.infoText}>2022-06-16</Text>
                <Heading style={mainStyle.mediumTitle}>Loonberekeningt</Heading>
              </Box>
            </Box>
            <Box style={{ width: '8%', paddingStart: 10 }}>
              <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} ></Icon>
            </Box>
          </Box>
        </Pressable>
      </Box>
    </AppContainer>
  );
}

export default DocumentsScreen;