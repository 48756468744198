import { HStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';

// waits for animations to finish before launching component

const InteractionWaiter = ({ ...props }) => {

    const [animationsDone, setAnimationsDone] = useState(false);

    let background = {
        linearGradient: {
            colors: ['#deeff8', '#fff'],
        },
    };

    useEffect(() => {
        setAnimationsDone(false);
        setTimeout(() => {
            InteractionManager.runAfterInteractions(() => {
                setAnimationsDone(true);
            });
        }, 0);

    }, []);

    if (!animationsDone) return (
        <HStack justifyContent={'center'} alignItems={'center'} h={'100%'} bg={background}>

        </HStack>
    );

    return <>{props.children}</>
};

export default InteractionWaiter;