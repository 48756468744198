import React from 'react';
import {Box, Pressable, Text} from 'native-base';
import {useNavigation} from '@react-navigation/native';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import SkillsAction from '../../../Actions/SkillsAction';

const SkillGroup = (props) => {
    // Init
    const skill = props.skill;
    const title = props.title;
    const selectedSkills = props.selectedSkills;
    const entityId = props.entityId;
    const parent = SkillsAction.getParent(skill);
    const navigation = useNavigation();
    const Icon = createIconSetFromFontello(lineAwesomeConfig);

    // Return view
    return (
        <Pressable style={{marginTop: 10}} onPress={() => navigation.navigate('ChildSkills', {
            id: skill.id,
            title: title,
            lvl: (skill.lvl + 1),
            selectedSkills: selectedSkills,
            entityId: entityId,
        })}>
            <Box style={{paddingVertical: 5, backgroundColor: parent.backgroundColorCode}}>
                <Text style={{
                    color: 'rgba(0,0,0,0.5)',
                    fontWeight: '600',
                    fontSize: 14,
                    lineHeight: 22,
                }}>
                    <Box style={{paddingStart: 3, paddingTop: 3}}>
                        <Icon name={'plus-circle'} size={24} style={{color: parent.colorCode}}/>
                    </Box>
                    <Box style={{paddingBottom: 4, paddingStart: 2}}>
                        {title}
                    </Box>
                </Text>
            </Box>
        </Pressable>
    );
};

export default SkillGroup;
