import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Heading, Switch, Divider } from 'native-base';
import main from '../../../Assets/Styles/main.json';
import Trans from '../../Components/Trans';
import APIAction from '../../../Actions/APIAction';

const mainStyle = StyleSheet.create(main);

const Settings = (props) => {

  // Init
  let user = props.user;

  let [profileChecked, setProfileChecked] = useState(user.employee.shareProfile);
  let [addJobsToCalendar, setAddJobsToCalendar] = useState(user.employee.addJobsToCalendar);

  let [alert1HourBeforeJob, setAlert1HourBeforeJob] = useState(user.employee?.notificationPreferences?.alert1HourBeforeJob);
  let [alert24HoursBeforeJob, setAlert24HoursBeforeJob] = useState(user.employee?.notificationPreferences?.alert24HoursBeforeJob);


  /**
   * Function to update the settings for the employee
   *
   * @param name
   * @param value
   * @returns {Promise<void>}
   */
  let toggleSetting = async (name, value) => {
    // Init
    let data = {};
    let url = '/api/employees/' + user.employee.id;

    // Format data
    if (name === 'shareProfile') {
      data = {
        shareProfile: value,
      };

      setProfileChecked(value);
    }

    if (name === 'addJobsToCalendar') {
      data = {
        addJobsToCalendar: value,
      };

      setAddJobsToCalendar(value);
    }

    if (name === 'alert1HourBeforeJob' || name === 'alert24HoursBeforeJob') {
      data.notificationPreferences = {
        alert1HourBeforeJob: alert1HourBeforeJob,
        alert24HoursBeforeJob: alert24HoursBeforeJob,
      };

      if (name === 'alert1HourBeforeJob') {
        data.notificationPreferences.alert1HourBeforeJob = value;
        setAlert1HourBeforeJob(value);
      }

      if (name === 'alert24HoursBeforeJob') {
        data.notificationPreferences.alert24HoursBeforeJob = value;
        setAlert24HoursBeforeJob(value);
      }
    }

    // Update employee
    await APIAction.request({ method: 'PATCH', url: url, body: data });
  };

  // View
  return (
    <>
      <Box style={mainStyle.boxItem}>
        <Box style={{ width: '85%' }}>
          <Heading style={mainStyle.mediumTitle}><Trans>Show my full profile to co-workers</Trans></Heading>
          <Text style={mainStyle.infoText}><Trans>Your colleagues will be able to see your name, contact details and profile picture.</Trans></Text>
        </Box>
        <Box style={{ width: '15%', paddingStart: 10 }}>
          <Switch offTrackColor="#beebe3" onTrackColor="#2ebfa5" onThumbColor="#fff" offThumbColor="#fff" onValueChange={(val) => toggleSetting('shareProfile', val)} isChecked={profileChecked} />
        </Box>
      </Box>
      {/*<Box style={mainStyle.boxItem}>
          <Box style={{ flexGrow: 1 }}>
            <Heading style={mainStyle.mediumTitle}>Minimal hourly wage</Heading>
            <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
              <RangeSlider
                  min={0}
                  max={100}
                  step={1}
              />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box style={{ width: '50%' }}>

                <Text
                    style={{
                      color: 'rgba(0,0,0,0.6)',
                      fontSize: 12,
                      textAlign: 'left'
                    }}>
                  11 hours
                </Text>
              </Box>
              <Box style={{ width: '50%' }}>

                <Text
                    style={{
                      color: 'rgba(0,0,0,0.6)',
                      fontSize: 12,
                      textAlign: 'right'
                    }}>
                  77 hours
                </Text>
              </Box>
            </Box>


          </Box>
        </Box> */}
      {/*<Box style={mainStyle.boxItem}>
        <Box style={{ flexGrow: 1 }}>
          <Heading style={mainStyle.mediumTitle}><Trans>Push notifications</Trans></Heading>
          <Box style={mainStyle.notificationItem}>
            <Box style={{ width: '85%' }}>
              <Heading style={mainStyle.normalTitle}><Trans>1 hour before a job</Trans></Heading>
            </Box>
            <Box style={{ width: '15%', paddingStart: 10 }}>
              <Switch offTrackColor="#beebe3" onTrackColor="#2ebfa5" onThumbColor="#fff" offThumbColor="#fff" onValueChange={(val) => toggleSetting('alert1HourBeforeJob', val)} isChecked={alert1HourBeforeJob} />
            </Box>
          </Box>
          <Divider my="2" style={mainStyle.dividerStyle} />
          <Box style={mainStyle.notificationItem}>
            <Box style={{ width: '85%' }}>
              <Heading style={mainStyle.normalTitle}><Trans>24 hours before a job</Trans></Heading>
            </Box>
            <Box style={{ width: '15%', paddingStart: 10 }}>
              <Switch offTrackColor="#beebe3" onTrackColor="#2ebfa5" onThumbColor="#fff" offThumbColor="#fff" onValueChange={(val) => toggleSetting('alert24HoursBeforeJob', val)} isChecked={alert24HoursBeforeJob} />
            </Box>
          </Box>
        </Box>
      </Box> */}
      <Box style={mainStyle.boxItem}>
        <Box style={{ width: '85%' }}>
          <Heading style={mainStyle.mediumTitle}><Trans>Put jobs into my Calendar</Trans></Heading>
          <Text style={mainStyle.infoText}><Trans>Skedle will automatically insert and update your job details, location, start and end hours in the Calendar on this mobile device</Trans></Text>
        </Box>
        <Box style={{ width: '15%', paddingStart: 10 }}>
          <Switch offTrackColor="#beebe3" onTrackColor="#2ebfa5" onThumbColor="#fff" offThumbColor="#fff" onValueChange={(val) => toggleSetting('addJobsToCalendar', val)} isChecked={addJobsToCalendar} />
        </Box>
      </Box>
    </>
  );
};

export default Settings;
