import React from 'react';
import { Pressable } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { v4 as uuidv4 } from 'uuid';
import Job from '../Jobs/Pending/Components/Job';

const JobView = (props) => {
    // Init
    const job = props.job;
    const navigation = useNavigation();
    let action = props.action;

    // Default action
    if (!action) {
        action = 'approve';
    }

    /**
     * Open the correct detail page based on action
     */
    let openDetailView = () => {
        // Open page to approve job
        if (action === 'approve') {
            navigation.navigate('ApproveJob', {
                id: job.id,
            });
        }

        // Open page to fill in hours of job
        if (action === 'fillInHours') {
            navigation.navigate('FillInHoursJob', {
                id: job.id,
            });
        }

        // Open page to open job preview
        if (action === 'preview') {
            navigation.navigate('Preview', {
                id: job.id,
            });
        }
    };

    return (
        <Pressable
            style={{ paddingLeft: 10 }}
            onPress={() => {
                openDetailView();
            }}
        >
            <Job key={uuidv4()} job={job} />
        </Pressable>
    );
};

export default JobView;
