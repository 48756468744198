import React from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Pressable, Box, Image, Text, Heading, Divider, Center, Button } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import ScaledImage from '../../../Components/ScaledImage';

import main from '../../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const JobOffer = (props) => {

  const navigation = useNavigation();

  // Return static data for now @Todo: fill in with real data, or use another view for apply flow?
  return (
    <>
      <Pressable
        style={mainStyle.boxItemVertical}
        onPress={() => {
          navigation.navigate('Apply');
        }}
      >
        <Box style={[mainStyle.jobHeader, {}]}>
          <Box style={[mainStyle.jobHeaderCompany, {}]}>
            <Box style={mainStyle.jobLogo}>
              <Image
                style={{
                  resizeMode: 'contain',
                  width: '100%',
                  height: '100%',
                }}
                source={require('../../../../Assets/Images/company.png')}
              />
            </Box>
            <Box style={mainStyle.jobCompanyName}>
              <Text style={[mainStyle.companyName, { paddingStart: 10 }]}>KAA Gent</Text>
            </Box>
          </Box>
          <Box style={[mainStyle.jobHeaderJob]}>
            <Heading style={[mainStyle.mediumTitle, { textAlign: 'left', fontSize: 22 }]}>Kelner</Heading>
          </Box>
        </Box>
        <Divider my="3" style={mainStyle.dividerStyle} />
        <Box style={mainStyle.imagePreview}>
          <Center>
            <ScaledImage borderRadius={10}
              height={720}
              uri={require('../../../../Assets/Images/map.png')}
            />
          </Center>

        </Box>
        <Box style={{ width: '100%', paddingTop: 10 }}>
          <Button
            style={mainStyle.blueButton}
            onPress={() => {

            }}
          >
            <Text style={mainStyle.blueButtonText}>Directions</Text>
          </Button>
        </Box>


        <Box style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 10, width: '100%' }}>
          <Box style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
            <Box>
              <Text style={{ fontWeight: '700', fontSize: 16 }}>4-9 jul.</Text>
            </Box>
            <Box style={{ paddingLeft: 10 }}>
              <Text style={mainStyle.timeLabel}>09:00 - 17:00</Text>
            </Box>
          </Box>
          <Box style={{ width: '35%' }}>
            <Box style={[mainStyle.greenButton, {
              flexDirection: 'row',
              alignItems: 'center',
              textAlign: 'center',
              alignSelf: 'flex-end',
            }]}>
              <Box>
                <Text style={{ color: '#2ebfa5' }}>3.8 </Text>
              </Box>
              <Box>
                <Icon name={'star'} size={13} style={{ color: '#2ebfa5' }} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box style={{ width: '100%' }}>
          <Text style={mainStyle.infoText}>Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's.</Text>
        </Box>
        <Box style={{ flexDirection: 'row', alignItems: 'center', width: '100%', paddingVertical: 10 }}>
          <Box>
            <Text style={{ fontSize: 18, color: '#556ee6', fontWeight: '700' }}>€ 11,14 / hr</Text>
          </Box>
        </Box>
      </Pressable>
      <Box style={mainStyle.boxItemVertical}>
        <Heading style={mainStyle.mediumTitle}>Contact person</Heading>
        <Box style={{ width: '100%', flexDirection: 'row', alignItems: 'center', paddingTop: 10 }}>
          <Box style={{ width: 40, alignItems: 'center' }}>
            <Box style={{
              backgroundColor: '#e9f5fb',
              height: 40,
              width: 40,
              borderRadius: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Text style={{
                fontWeight: '700',
                color: '#00aaff',
                textAlign: 'center',
                fontSize: 18,
              }}>H</Text>
            </Box>
          </Box>
          <Box style={{ flex: 1, paddingLeft: 10 }}>
            <Heading style={{ fontSize: 14, fontWeight: '500' }}>Hilde Vandamme</Heading>
          </Box>
          <Box style={{ width: 100, alignItems: 'center', flexDirection: 'row' }}>
            <Box style={{
              width: '50%',
              borderRadius: 20,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Pressable
                onPress={() => {
                  navigation.navigate('/');
                }}
              >
                <Box style={{ width: '100%' }}>
                  <Icon name={'phone'} size={26} style={{ color: '#00aaff' }} />
                </Box>

              </Pressable>
            </Box>
            <Box style={{
              width: '50%',
              borderRadius: 20,
              alignItems: 'center',
              borderLeftColor: '#eaecee',
              borderLeftWidth: 1,
              borderLeftStyle: 'solid',
              justifyContent: 'center',
            }}>
              <Pressable
                onPress={() => {
                  navigation.navigate('/');
                }}
              >
                <Box style={{ width: '100%' }}>
                  <Icon name={'alternate-comment'} size={26} style={{ color: '#00aaff' }} />
                </Box>

              </Pressable>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box style={mainStyle.boxItemVertical}>
        <Heading style={mainStyle.mediumTitle}>Messages</Heading>
        <Box style={mainStyle.messageItem}>
          <Box style={{ width: '100%', flexDirection: 'row', alignItems: 'center', paddingVertical: 10 }}>
            <Box style={{ width: 40, alignItems: 'center' }}>
              <Box style={{
                backgroundColor: '#e9f5fb',
                height: 40,
                width: 40,
                borderRadius: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Text style={{
                  fontWeight: '700',
                  color: '#00aaff',
                  textAlign: 'center',
                  fontSize: 18,
                }}>H</Text>
              </Box>
            </Box>
            <Box style={{ flex: 1, paddingLeft: 10 }}>
              <Heading style={{ fontSize: 14, fontWeight: '500' }}>Hilde Vandamme</Heading>
            </Box>
            <Box style={{
              width: '45%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingLeft: 10,
            }}>
              <Text style={mainStyle.timeLabel}>17:04</Text>
            </Box>
          </Box>
          <Text style={[mainStyle.infoText, { fontStyle: 'italic' }]}>Be sure to wear a black shirt and
            black shoes</Text>
        </Box>
        <Box style={mainStyle.messageItem}>
          <Box style={{ width: '100%', flexDirection: 'row', alignItems: 'center', paddingVertical: 10 }}>
            <Box style={{ width: 40, alignItems: 'center' }}>
              <Box style={{
                backgroundColor: '#e9f5fb',
                height: 40,
                width: 40,
                borderRadius: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Text style={{
                  fontWeight: '700',
                  color: '#00aaff',
                  textAlign: 'center',
                  fontSize: 18,
                }}>H</Text>
              </Box>
            </Box>
            <Box style={{ flex: 1, paddingLeft: 10 }}>
              <Heading style={{ fontSize: 14, fontWeight: '500' }}>Hilde Vandamme</Heading>
            </Box>
            <Box style={{
              width: '45%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingLeft: 10,
            }}>
              <Text style={mainStyle.timeLabel}>Yesterday, 14:02</Text>
            </Box>
          </Box>
          <Text style={[mainStyle.infoText, { fontStyle: 'italic' }]}>Be sure to wear a black shirt and
            black shoes</Text>
        </Box>
      </Box>
      <Box style={mainStyle.boxItemVertical}>
        <Box style={{ width: '100%' }}>
          <Heading style={mainStyle.mediumTitle}>Friends who work on this job:</Heading>

          <Pressable
            onPress={() => {
              navigation.navigate('/');
            }}
          >
            <Box style={{ paddingVertical: 5, alignItems: 'center', flexDirection: 'row' }}>
              <Box style={{ width: 60 }}>
                <Image size={50}
                  style={{ borderRadius: 25 }}
                  source={require('../../../../Assets/Images/user-1.png')}
                />
              </Box>
              <Box style={{ flex: 1 }}>
                <Heading style={{ fontSize: 14, fontWeight: '500' }}>Wim Schiettecat</Heading>
              </Box>
              <Box style={{ width: '8%', paddingStart: 10 }}>
                <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
              </Box>
            </Box>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.navigate('/');
            }}
          >
            <Box style={{ paddingVertical: 5, alignItems: 'center', flexDirection: 'row' }}>
              <Box style={{ width: 60 }}>
                <Image size={50}
                  style={{ borderRadius: 25 }}
                  source={require('../../../../Assets/Images/user-2.png')}
                />
              </Box>
              <Box style={{ flex: 1 }}>
                <Heading style={{ fontSize: 14, fontWeight: '500' }}>Vincent Dierickx</Heading>
              </Box>
              <Box style={{ width: '8%', paddingStart: 10 }}>
                <Icon name={'angle-right'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
              </Box>
            </Box>
          </Pressable>

        </Box>
      </Box>
    </>
  );
};

export default JobOffer;