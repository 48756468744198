import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Image, Skeleton, Box, Text } from 'native-base';
import FileFunctions from '../../Libs/FileFunctions';

import main from '../../Assets/Styles/main.json';
const mainStyle = StyleSheet.create(main);

const ClientImage = (props) => {
  const [image, setImage] = useState(false);

    //on load
    useEffect(() => {
      const init = async () => {
        if (typeof props.client.logo === 'string') {
          let result = await FileFunctions.download({ url: props.client.logo });
          setImage(
            <Image
              alt={'Client logo'}
              style={[mainStyle.jobLogo, props.style]}
              source={result}
            />
          )
        } else {
          let initials = '';
          if (props.client.name?.length > 0) {
            initials += props.client.name.slice(0, 1).toUpperCase();
          }
          setImage(
            <Box style={[mainStyle.jobLogo, props.style, { justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }]}>
              <Text p={0}>{initials}</Text>
            </Box>
          );
        }
      }
      init();
    }, [props.client, props.style]);

    if (image) {
      return image;
    }

    return (
      <Skeleton
        style={[mainStyle.jobLogo, props.style]}
      />
    );
};

export default ClientImage;