import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Actionsheet, Text, Modal, Button } from 'native-base';
import Webcam from 'react-webcam';
import Trans from '../../Views/Components/Trans';
import { v4 as uuidv4 } from 'uuid';
import types from './types.json';
import { Image } from 'image-js';

import main from '../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create(main);

const FilePicker = (props) => {

    const [id, setId] = useState('picker-' + uuidv4());
    const [open, setOpen] = useState(false);
    const [showWebcamModal, setShowWebcamModal] = useState(false);
    const webcamRef = useRef(null);

    let inputProps = {
        id: id,
        type: 'file',
        name: 'file'
    };

    //if image picker
    if ('imageOnly' in props) {
        inputProps['accept'] = 'image/png, image/jpeg, image/tiff';
    }

    //type restrictions are set
    if (typeof props.types === 'object') {
        let platformTypes = [];
        for (let type of props.types) {
            platformTypes.push(type.mime);
        }
        inputProps['accept'] = platformTypes.join(', ');
    }

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const trigger = (value) => {
        setOpen(value);
        if (typeof props.onTrigger === 'function') {
            props.onTrigger(value);
        }
    };

    const resizeImage = useCallback((image) => {

        const dataURLToBlob = (dataurl) => {
            let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        };

        const blobToDataURL = async (file) => {
            //read file
            return await new Promise((resolve) => {
                let reader = new FileReader();
                reader.addEventListener('load', (e) => {
                    let fileContent = e.target.result;
                    resolve(JSON.parse(JSON.stringify({ uri: fileContent, name: file.name })));
                });
                reader.readAsDataURL(file);
            });
        };

        const init = async (image) => {

            //decode image
            let imageBlob = dataURLToBlob(image.uri);
            let imageArray = await imageBlob.arrayBuffer();
            let decodedImage = await Image.load(imageArray);

            //get info
            let imgWidth = decodedImage.width;
            let imgHeight = decodedImage.height;

            let longSide = Math.max(imgWidth, imgHeight);

            //if image is too big
            if (longSide > 1920) {
                let ratio = 1920 / longSide;
                let newWidth = Math.round(imgWidth * ratio);
                let newHeight = Math.round(imgHeight * ratio);
                decodedImage = decodedImage.resize({ width: newWidth, height: newHeight });
            }

            //convert
            let newImage = await decodedImage.toBlob('image/jpeg', '0.8');

            //create new file name
            let newFileName = JSON.parse(JSON.stringify(image.name));
            newFileName = newFileName.split('.');
            newFileName.pop();
            newFileName = newFileName.join('.');
            newFileName = newFileName + '.jpg';

            newImage = new File([newImage], newFileName, { type: 'image/jpeg' });

            //return
            return await blobToDataURL(newImage);
        };
        return init(image);
    }, []);

    const getImage = async () => {
        let picker = document.getElementById(id);
        picker = picker.cloneNode(true);

        picker.click();

        //click handler
        const clickHandler = (event) => {
            let files = event.target.files;
            if (files.length > 0) {
                //return
                if (typeof props.onChange === 'function') {
                    let file = files[0];

                    //read file
                    let reader = new FileReader();
                    reader.addEventListener('load', (e) => {
                        let fileContent = e.target.result;
                        let image = JSON.parse(JSON.stringify({ uri: fileContent, name: file.name }));
                        resizeImage(image).then((resizedImage) => {
                            props.onChange(resizedImage);
                        });
                    });
                    reader.readAsDataURL(file);
                }
            }
        };

        //listen for click
        picker.addEventListener('change', clickHandler);
    };

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        let image = JSON.parse(JSON.stringify({ uri: imageSrc, name: `webcam-${new Date().getTime()}.jpg` }));
        resizeImage(image).then((resizedImage) => {
            props.onChange(resizedImage);
            setShowWebcamModal(false);
        });
    }, [webcamRef, props, resizeImage]);

    return (
        <>
            <Box style={{ height: 0, width: 0, margin: 0, padding: 0, border: 0, opacity: 0 }}>
                <input {...inputProps} />
            </Box>
            <Actionsheet isOpen={open} onClose={() => trigger(false)}>
                <Actionsheet.Content>
                    <Actionsheet.Item onPress={() => setShowWebcamModal(true)}><Text><Trans>Take photo</Trans></Text></Actionsheet.Item>
                    <Actionsheet.Item onPress={() => getImage()}><Text><Trans>Upload</Trans></Text></Actionsheet.Item>
                    <Actionsheet.Item onPress={() => trigger(false)}><Text><Trans>Cancel</Trans></Text></Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
            <Modal isOpen={showWebcamModal} onClose={() => setShowWebcamModal(false)} size={'xl'}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text style={[mainStyle.modalTitle]}><Trans>Webcam</Trans></Text></Modal.Header>
                    <Modal.Body>
                        {showWebcamModal &&
                            <Webcam
                                ref={webcamRef}
                                screenshotFormat={'image/jpeg'}
                                videoConstraints={{
                                    width: 1080,
                                    height: 1080,
                                    facingMode: 'user'
                                }}
                            />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button w={'100%'} onPress={capture}>
                            <Text color={'#fff'}><Trans>Take image</Trans></Text>
                        </Button>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </>
    );
};

const ImagePicker = (props) => {
    return (
        <FilePicker {...props} imageOnly />
    );
}

export { ImagePicker, FilePicker as DocumentPicker, types };