import { Model } from '@nozbe/watermelondb';
import { text, date } from '@nozbe/watermelondb/decorators';

export default class Translation extends Model {
    static table = 'request_cache';

    @text('hash_value') hash;
    @date('created_at') createdAt;
    @date('finished_at') finishedAt;
    @text('method_value') method;
    @text('url_value') url;
    @text('params_value') params;
    @text('body_value') body;
    @text('response_value') response;
};