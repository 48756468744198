import React, { useCallback, useEffect, useRef, useState } from 'react';
import APIAction from '../../Actions/APIAction';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl';

const Mapbox = (props) => {

    const
        mapContainer = useRef(null),
        map = useRef(null),
        firstLoad = useRef(true),
        [ready, setReady] = useState(false)
        ;

    const onFirstLoad = useCallback(() => {
        const init = async () => {
            let token = await APIAction.request({
                method: 'GET',
                url: '/api/mapbox_token'
            });
            mapboxgl.accessToken = token.token;
            setReady(true);

            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: props.coordinate,
                zoom: props.zoom
            });

            new mapboxgl.Marker()
                .setLngLat(props.coordinate)
                .addTo(map.current);

            map.current.addControl(new mapboxgl.NavigationControl());
        };
        init();
    }, [props.coordinate, props.zoom]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad]);

    return (
        <>
            {
                ready ?
                    <div ref={mapContainer} className={'map-container'} style={props.style} />
                    :
                    <></>
            }
        </>

    );

};

export default Mapbox;