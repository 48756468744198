import Config from '../Libs/Config';
import APIAction from './APIAction';
import database from '../Database/Database';
import { Q } from '@nozbe/watermelondb';

const TranslationAction = {

    /**
     * Update translation database
     */
    update: async () => {

        //get translations
        let response = await APIAction.request({ method: 'GET', url: '/api/translations', authenticate: false, catchFail: false });

        if (response) {
            //open writer
            await database.write(async () => {

                //delete previous entries
                await database.get('language').query().destroyAllPermanently();

                //loop and add languages
                let languageArray = {};
                for (let [key, value] of Object.entries(response.languages)) {
                    let dbLanguage = await database.get('language').create(language => {
                        language.code = key;
                        language.name = value;
                    });
                    languageArray[key] = dbLanguage;
                }

                //delete previous entries
                await database.get('translation').query().destroyAllPermanently();

                //loop and add translations
                for (let [languageCode, trans] of Object.entries(response.translations)) {

                    if (Object.keys(languageArray).includes(languageCode)) {
                        let usedLanguage = languageArray[languageCode];

                        //loop over items
                        for (let [key, value] of Object.entries(trans)) {
                            await database.get('translation').create(translation => {
                                translation.key = key;
                                translation.value = value;
                                translation.language.set(usedLanguage);
                            });
                        }
                    }
                }

                //add sync
                await database.get('translation_sync').create(translationSync => {
                    translationSync.performedAt = new Date();
                });

            });
        }
    },

    getAllLanguages: async () => {
        return await database.get('language').query();
    },

    updateIfRequired: async () => {
        let lastSync = await database.get('translation_sync').query(
            Q.sortBy('performed_at', Q.desc),
            Q.take(1),
        );

        if (lastSync && (lastSync.length > 0)) {
            lastSync = lastSync[0];
        } else {
            lastSync = null;
        }

        if (null === lastSync) {
            await TranslationAction.update();
        } else {
            let oldDate = lastSync.performedAt;
            let newDate = new Date();

            let diffTime = Math.abs(newDate - oldDate);
            let diffHours = Math.round(diffTime / (1000 * 60 * 60));

            if (parseInt(Config.TRANSLATION_TIMEOUT) <= diffHours) {
                await TranslationAction.update();
            }
        }
    },

    getSelectedLanguage: async () => {
        //get selected language from db
        let selectedLanguage = await database.get('language').query(
            Q.where('is_selected', true),
            Q.take(1)
        );

        //if no language is selected
        if (selectedLanguage.length === 0) {
            selectedLanguage = await TranslationAction.setSelectedLanguage(Config.LOCALE);
        } else {
            selectedLanguage = selectedLanguage[0];
        }

        if (null === selectedLanguage) {
            return {code: 'en', name: 'English'};
        }

        return selectedLanguage;
    },

    setSelectedLanguage: async (code) => {
        //get all languages
        let languages = await database.get('language').query();

        //language object
        let languageObject = null;

        //change values
        await database.write(async () => {
            for (let language of languages) {
                if (language.code === code) {
                    await language.update(() => {
                        language.isSelected = true;
                    });
                    languageObject = language;
                } else {
                    await language.update(() => {
                        language.isSelected = false;
                    });
                }
            }
        });

        //return
        return languageObject;
    },

    translate: async (key) => {

        //get currently selected language
        let language = await TranslationAction.getSelectedLanguage();

        //check if not null
        if (null === language || typeof language === 'undefined' || language.id === null || typeof language.id === 'undefined') return key;

        let translation = await database.get('translation').query(
            Q.where('key', key),
            Q.where('language_id', language.id),
            Q.take(1)
        );

        //check if found
        if (translation.length === 0) return key;

        return translation[0].value;
    },

    translateInLine: async (arr) => {
        let result = {};
        for (let item of arr) {
            result[item] = await TranslationAction.translate(item);
        }
        return result;
    },

    translateArray: async (arr) => {
        //get currently selected language
        let language = await TranslationAction.getSelectedLanguage();

        if (language.code in arr) {
            return arr[language.code];
        }
        if ('en' in arr) {
            return arr['en'];
        }
        return arr[Object.keys(arr)[0]];
    }
};

export default TranslationAction;