// Do nothing for web
const checkCalendarPermissions = async () => {
    return false;
};

const addEvents = async () => {
    return false;
};

const removeEvents = async () => {
    return false;
};


export { checkCalendarPermissions, addEvents, removeEvents};
