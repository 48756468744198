import React, {useEffect, useState} from 'react';
import {Box, Button, Heading, Skeleton, Text} from 'native-base';
import AppContainer from '../Components/AppContainer';
import {v4 as uuidv4} from 'uuid';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import {StyleSheet} from 'react-native';
import main from '../../Assets/Styles/main.json';
import {createIconSetFromFontello} from 'react-native-vector-icons';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import TranslationAction from '../../Actions/TranslationAction';
import Trans from '../Components/Trans';
import SkillsAction from '../../Actions/SkillsAction';
import SkillGroup from './Components/SkillGroup';
import SkillCheckbox from './Components/SkillCheckbox';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ChildSkillsScreen = ({route}) => {
    // Init
    const isFocused = useIsFocused();
    const navigation = useNavigation();

    let mainTitle = '';
    let id = '';
    let lvl = 0;
    let entityId = '';

    let [language, setLanguage] = useState(null);
    let [previousParent, setPreviousParent] = useState('');
    let [previousTitle, setPreviousTitle] = useState('');
    let [previousLvl, setPreviousLvl] = useState('');
    let [selectedSkills, setSelectedSkills] = useState(route.params.selectedSkills);

    let [skillElements, setSkillElements] = useState([
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'}/>,
        <Skeleton key={uuidv4()} h={'40'} marginBottom={'3'}/>,
    ]);

    // Get params
    if (route.params) {
        if (route.params.id) {
            id = route.params.id;
        }

        if (route.params.title) {
            mainTitle = route.params.title;
        }

        if (route.params.lvl) {
            lvl = route.params.lvl;
        }

        if (route.params.entityId) {
            entityId = route.params.entityId;
        }
    }

    /**
     * Function to handle skill changes
     *
     * @param skillId
     */
    let handleSkill = (skillId) => {
        let action = 'add';

        if (selectedSkills.includes(skillId)) {
            action = 'remove';
        }

        toggleSkill(skillId, action);
    };

    /**
     * Function to toggle a skill
     *
     * @param skillId
     * @param action
     * @returns {Promise<void>}
     */
    let toggleSkill = async (skillId, action = 'add') => {
        // Add/remove skill
        await SkillsAction.toggleSkill(skillId, entityId, action);

        if (action === 'add') {
            selectedSkills.push(skillId);
        } else {
            selectedSkills = selectedSkills.filter(function (value) {
                return value !== skillId;
            });
        }

        setSelectedSkills(selectedSkills);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Get language
            setLanguage(await TranslationAction.getSelectedLanguage());

            // Force state refresh?
            setSkillElements([]);

            // Get skills
            let checkboxes = [];
            let skills = await SkillsAction.findSkills(lvl,id);

            if ('hydra:member' in skills) {
                skills = skills['hydra:member'];
            }

            // Loop through skills
            for (const key in skills) {
                let skill = skills[key];

                // Set values for back button
                setPreviousParent(skill.parent.parent.id);
                setPreviousLvl(skill.parent.lvl);

                let parentTitle = skill.parent.parent.title.en;

                if (language && skill.parent.parent.title.hasOwnProperty(language.code)) {
                    parentTitle = skill.parent.parent.title[language.code];
                }

                setPreviousTitle(parentTitle);

                // Get title
                let title = skill.title.en;

                if (language && skill.title.hasOwnProperty(language.code)) {
                    title = skill.title[language.code];
                }

                // Create elements
                if (skill.children.length === 0) {
                    checkboxes.push(
                        <SkillCheckbox key={uuidv4()} skill={skill} title={title} selectedSkills={selectedSkills} handleSKill={() => {
                            handleSkill(skill.id);
                        }}/>
                    );
                } else {
                    checkboxes.push(
                        <SkillGroup key={uuidv4()} skill={skill} title={title} selectedSkills={selectedSkills} entityId={entityId} />
                    );
                }
            }

            setSkillElements(checkboxes);
        };

        // Fetch data
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isFocused, language, lvl, navigation]);

    // Return view
    return (
        <AppContainer>
            <Box style={mainStyle.boxItemVertical}>
                <Box style={mainStyle.stepItemWrapper}>
                    <Box style={{flex: 1}}>
                        <Button colorScheme={'light'} variant={'subtle'}
                                onPress={() => previousLvl > 2 ? navigation.navigate('ChildSkills', {
                                    id: previousParent,
                                    title: previousTitle,
                                    lvl: previousLvl,
                                    selectedSkills: selectedSkills,
                                    entityId: entityId,
                                }) : navigation.replace('OverviewProfile')}>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <Box style={{display: 'flex'}}>
                                    <Icon style={{color: '#00aaff'}} name={'angle-left'} size={13}/>
                                </Box>
                                <Box style={{flexGrow: 1, flexShrink: 1}}>
                                    <Text color={'primary.600'} fontSize={13}><Trans>Back</Trans></Text>
                                </Box>

                            </Box>
                        </Button>
                    </Box>
                    <Box style={{width: 175, paddingStart: 10}}>
                        <Button colorScheme={'light'} variant={'subtle'} onPress={() => {
                            navigation.replace('OverviewProfile');
                        }}>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <Box style={{flexGrow: 1, flexShrink: 1}}>
                                    <Text color={'primary.600'} fontSize={13}><Trans>Close</Trans></Text>
                                </Box>
                            </Box>
                        </Button>
                    </Box>
                </Box>
                <Box style={{width: '100%', marginTop: 20}}>
                    <Heading style={mainStyle.mediumTitle}>{mainTitle}</Heading>

                    <Box style={{marginTop: 10}}>
                        {skillElements}
                    </Box>
                </Box>
            </Box>
        </AppContainer>
    );
};

export default ChildSkillsScreen;
