const HashGenerator = {

    //hash a value
    hash: async (value) => {
        const encoder = new TextEncoder();
        let data = encoder.encode(value);
        let hashBuffer = await crypto.subtle.digest('SHA-256', data);
        let hashArray = Array.from(new Uint8Array(hashBuffer));
        let hash = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return hash;
    }
};

export default HashGenerator;