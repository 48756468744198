import React, { useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Image, Heading, Button, FormControl, Input, Pressable } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';

import main from '../../Assets/Styles/main.json';

import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const mainStyle = StyleSheet.create({ ...main });

const ResetPasswordScreen = ({ route, navigation }) => {

    // Init
    let passwordRef = useRef();
    let confirmPasswordRef = useRef();
    let [loading, setLoading] = useState(false);
    let [invalid, setInvalid] = useState(false);
    let [viewPassword, setViewPassword] = useState(false);
    let [password, setPassword] = useState('');
    let [confirmedPassword, setConfirmedPassword] = useState('');

    const resetPassword = async () => {
        // Get secret
        let secret = '';
        if (route.params) {
            if (route.params.secret) {
                secret = route.params.secret;
            }
        }

        // Check if password match
        if (password === confirmedPassword){
            // Check if password is at least 8 characters and has a special character and a number
            //let passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;

            if (password.length >= 8){
                setLoading(true);
                setInvalid(false);

                // Reset password request
                let data = {
                    secret: secret,
                    plainPassword: password,
                };

                let response = await APIAction.request({url: '/api/anonymous/change_password', method: 'PATCH', body: data, returnErrors: true});

                // Handle response
                if (response && response.error){
                    // Show error
                    GeneralAction.toast('error', response.error);
                } else {
                    // Redirect to login
                    GeneralAction.toast('success', 'Password has been updated');

                    setLoading(false);
                    navigation.navigate('Login');
                }

            } else {
                // Show error if requirements are not met
                setInvalid(true);
                //GeneralAction.toast('error', 'Password must contain at least 8 characters, a capital, a number and a special character');
                GeneralAction.toast('error', 'Password must contain at least 8 characters');
            }

        } else {
            // Set error if passwords don't match
            setInvalid(true);
            GeneralAction.toast('error', 'Please make sure that the passwords match');
        }
    };

    return (
        <AppContainer
            noGradient
            noTabBar
            footer={
                <Box style={mainStyle.registrationAction}>
                    <Box style={{ width: '100%' }}>
                        <Button
                            style={{ backgroundColor: '#00aaff', borderRadius: 10, width: '100%' }}
                            isLoading={loading}
                            isDisabled={loading}
                            onPress={resetPassword}
                        >
                            <Text style={{ color: '#fff', fontWeight: '600', fontSize: 17, textAlign: 'center', padding: 10 }}><Trans>Reset password</Trans></Text>
                        </Button>
                    </Box>
                </Box>
            }
        >
            {/* Header */}
            <Box style={mainStyle.headerJoin}>
                <Image
                    source={require('../../Assets/Images/logo.png')}
                    height={20}
                    width={40}
                    resizeMode={'contain'}
                />
            </Box>

            {/* Content */}
            <Box style={mainStyle.contentWrapperJoin}>
                <Heading style={{ color: '#000', fontWeight: '500', lineHeight: 36, textAlign: 'center', fontSize: 28 }}><Trans>Choose password</Trans></Heading>
                <Box style={{ width: '100%', maxWidth: 500 }}>
                    <Box style={{ width: '100%', maxWidth: '100%' }} mt="2">
                        <FormControl isRequired isInvalid={invalid}>
                            <FormControl.Label><Text><Trans>Password:</Trans></Text></FormControl.Label>
                            <Input
                                ref={passwordRef}
                                type={viewPassword ? 'text' : 'password'}
                                variant={'filled'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                autoComplete={'password'}
                                returnKeyType={'done'}
                                keyboardType={'default'}
                                secureTextEntry={!viewPassword}
                                onChangeText={(text) => {
                                    setPassword(text);
                                }}
                                InputRightElement={
                                    <Pressable
                                        onPress={() => {
                                            setViewPassword(!viewPassword);
                                        }}
                                    >
                                        <Icon name={viewPassword ? 'eye-slash' : 'eye'} size={16} style={{ marginRight: 5 }} />
                                    </Pressable>
                                }
                                onSubmitEditing={() => {
                                    passwordRef.current.focus();
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box style={{ width: '100%', maxWidth: '100%' }} mt="2">
                        <FormControl isRequired isInvalid={invalid}>
                            <FormControl.Label><Text><Trans>Confirm password:</Trans></Text></FormControl.Label>
                            <Input
                                ref={confirmPasswordRef}
                                type={viewPassword ? 'text' : 'password'}
                                variant={'filled'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                autoComplete={'password'}
                                returnKeyType={'done'}
                                keyboardType={'default'}
                                secureTextEntry={!viewPassword}
                                onChangeText={(text) => {
                                    setConfirmedPassword(text);
                                }}
                                InputRightElement={
                                    <Pressable
                                        onPress={() => {
                                            setViewPassword(!viewPassword);
                                        }}
                                    >
                                        <Icon name={viewPassword ? 'eye-slash' : 'eye'} size={16} style={{ marginRight: 5 }} />
                                    </Pressable>
                                }
                                onSubmitEditing={() => {
                                    resetPassword();
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </AppContainer>
    );
};

export default ResetPasswordScreen;
