import { Model, Q } from '@nozbe/watermelondb';
import { date, lazy } from '@nozbe/watermelondb/decorators';

export default class TranslationSync extends Model {
    static table = 'translation_sync';

    @date('performed_at') performedAt;

    @lazy lastSync = this.collection.extend(
        Q.sortBy('performed_at', Q.desc),
        Q.take(1),
    )
};