import database from '../Database/Database';
import { Q } from '@nozbe/watermelondb';

const SettingAction = {
    set: async (key, value) => {
        await database.write(async () => {
            await database.get('setting').create(setting => {
                setting.key = key;
                setting.value = value;
                setting.updatedAt = new Date();
            });
        });
    },

    get: async (key) => {
        let result = null;

        const value = await database.get('setting').query(
            Q.where('key', key),
            Q.sortBy('updated_at', Q.desc),
            Q.take(1),
        );

        if (value.length === 1) {
            result = value[0];
            result = result.value;
        }

        return result;
    },

    rem: async (key) => {

        const value = await database.get('setting').query(
            Q.where('key', key),
        );

        if (value.length > 0) {
            for (const item of value) {
                await database.write(async () => {
                    await item.destroyPermanently();
                });
            }
            return true;
        }

        return false;
    }
};

export default SettingAction;
