import { Model } from '@nozbe/watermelondb';
import { text, json, field } from '@nozbe/watermelondb/decorators';

const sanitizeEmployee = json => json;
const sanitizeLanguage = json => json;

export default class CurrentUser extends Model {
    static table = 'current_user';

    @text('user_id') userId;
    @text('email') email;
    @text('phone') phone;
    @text('first_name') firstName;
    @text('last_name') lastName;
    @json('employee', sanitizeEmployee) employee;
    @json('language', sanitizeLanguage) language;
    @text('client') client;
    @field('is_identity_verified') isIdentityVerified;
    @field('is_payment_verified') isPaymentVerified;
};