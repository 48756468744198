import React from 'react';
import {Box, Checkbox, Text} from 'native-base';
import SkillsAction from '../../../Actions/SkillsAction';
import {Platform} from "react-native";

const SkillCheckbox = (props) => {
    // Init
    const skill = props.skill;
    const title = props.title;
    const selectedSkills = props.selectedSkills;
    const parent = SkillsAction.getParent(skill);
    let handleSkill = props.handleSKill;

    // Return view
    return (
        <Box style={{paddingVertical: 5, paddingStart: 2}}>
            <Checkbox size="md" width="100%" style={{alignSelf: 'flex-start'}}
                      _hover={{bg: parent.colorCode, borderColor: parent.backgroundColorCode}}
                      _pressed={{bg: parent.colorCode, borderColor: parent.backgroundColorCode}}
                      _checked={{bg: parent.colorCode, borderColor: parent.backgroundColorCode}}
                      borderRadius="50"
                      borderColor="#eaecee"
                      borderStyle="solid" borderWidth="1" bg="#fff"
                      accessibilityLabel="This is a dummy checkbox"
                      defaultIsChecked={selectedSkills.includes(skill.id)}
                      value={skill.id}
                      onPress={handleSkill}
                      onChange={Platform.OS == 'web' ? handleSkill : false}
            >
                <Text style={{
                    color: 'rgba(0,0,0,0.5)',
                    fontWeight: '600',
                    fontSize: 14,
                    lineHeight: 22,
                }}>{title}</Text>
            </Checkbox>
        </Box>
    );
};

export default SkillCheckbox;
