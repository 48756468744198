import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Image, Skeleton, Box, Text } from 'native-base';
import FileFunctions from '../../Libs/FileFunctions';

import main from '../../Assets/Styles/main.json';
const mainStyle = StyleSheet.create(main);

const UserImage = (props) => {
    const [image, setImage] = useState(false);

    //on load
    useEffect(() => {
        const init = async () => {
            if (typeof props.user.image === 'string') {
                let result = await FileFunctions.download({ url: props.user.image });
                setImage(
                    <Image
                        alt={'Profile picture'}
                        style={[mainStyle.userCircle, props.style]}
                        source={result}
                    />
                )
            } else {
                let initials = '';
                if (props.user.firstName?.length > 0) {
                    initials += props.user.firstName.slice(0, 1).toUpperCase();
                }
                if (props.user.lastName?.length > 0) {
                    initials += props.user.lastName.slice(0, 1).toUpperCase();
                }
                setImage(
                    <Box style={[mainStyle.userCircle, props.style, { justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }]}>
                        <Text p={0} style={[props.textStyle]}>{initials}</Text>
                    </Box>
                );
            }
        }
        init();
    }, [props.user, props.style, props.textStyle]);

    if (image) {
        return image;
    }

    return (
        <Skeleton
            style={[mainStyle.userCircle, props.style]}
        />
    );
};

export default UserImage;