import React, { useEffect, useState } from 'react';
import { Linking, StyleSheet, TouchableOpacity } from 'react-native';
import main from '../../../../Assets/Styles/main.json';
import { Box, HStack, Heading, Pressable, Text, IconButton, useTheme } from 'native-base';
import UserImage from '../../../Components/UserImage';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import GeneralAction from '../../../../Actions/GeneralAction';


const mainStyle = StyleSheet.create(main);

const Colleague = (props) => {
    const { colors } = useTheme();
    const Icon = createIconSetFromFontello(lineAwesomeConfig);

    return (
        <Box>
            <HStack
                space={2}
                alignItems={'center'}
            >
                <Box
                    flexGrow={1}
                    flexShrink={1}
                >
                    <HStack
                        space={2}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <HStack style={{ flexShrink: 1}} space={2} alignItems={'center'}>
                            <UserImage
                                style={{ height: 37, width: 37 }}
                                user={props.user.employee.user}
                            />
                            <Text style={{flexShrink: 1}}>
                                {
                                    (props.user.employee.user.firstName || props.user.employee.user.lastName) &&
                                    <>{props.user.employee.user.firstName} {props.user.employee.user.lastName}</>
                                }
                                {
                                    !props.user.employee.user.firstName && !props.user.employee.user.lastName &&
                                    <>{props.user.employee.user.email}</>
                                }

                            </Text>
                        </HStack>
                        <TouchableOpacity 
                            style={{ margin: 0, flexDirection: 'column' }}
                            onPress={async () => {
                                // Call user
                                let url = 'tel:' + GeneralAction.trim(props.user.employee.user.phone);
                                await Linking.openURL(url);
                            }}>
                                <Text style={mainStyle.timeLabel} fontSize={10}><Icon name={'phone'} size={12} color={colors['primary']['600']} style={{ marginRight: 5 }} /> {props.user.employee.user.phone}</Text>
                        </TouchableOpacity>
                    </HStack>
                </Box>
            </HStack>
        </Box>
    );
};

export default Colleague;
