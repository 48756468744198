import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Heading, Text, Button } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import Trans from '../../Components/Trans';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import main from '../../../Assets/Styles/main.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const CheckListItem = (props) => {
  let pending = false;

  if (props.pending){
    pending = props.pending;
  }

  let buttonText = <Trans>Fill in</Trans>;

  if(props.buttonText){
    buttonText = props.buttonText;
  }

  return (
    <Box style={props.style}>
      {!props.first &&
        <Box style={mainStyle.stepItemDividerWrapper}>
          <Box style={mainStyle.stepItemDivider} />
        </Box>
      }
      <Box style={mainStyle.stepItemWrapper}>
        <Box style={mainStyle.stepItemCircleWrapper}>
          <Box style={[mainStyle.stepItemCircle, { backgroundColor: props.ready ? '#2ebfa5' : '#fff', borderColor: props.ready ? '#2ebfa5' : 'rgba(219,220,222,0.6)' }]}>
            {props.ready &&
              <Icon name={'check'} size={15} style={{ color: '#ffffff' }} />
            }
          </Box>
        </Box>
        <Box style={{ flex: 1 }}>
          <Text style={{ fontSize: 12, color: props.active ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.5)', marginBottom: 0, lineHeight: 14 }}><Trans>Step</Trans> {props.number}</Text>
          <Box style={{ alignItems: 'center', flexDirection: 'row' }}>
            <Box>
              <Heading style={{ fontSize: 15, marginTop: 0, lineHeight: 18, fontWeight: '600', color: props.active ? 'rgb(23,23,23)' : 'rgba(0,0,0,0.5)' }}>{props.title}</Heading>
            </Box>
            {props.star &&
              <Box>
                <Icon name={'star'} size={13} style={{ color: '#00aaff' }} />
              </Box>
            }
          </Box>
        </Box>
        <Box style={mainStyle.stepItemButtonWrapper}>
          {typeof props.onPress === 'function' &&
              <>
                {
                  !pending &&
                    <Button colorScheme={'light'} variant={'subtle'} onPress={() => {
                      props.onPress();
                    }}>
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box style={{ flexGrow: 1, flexShrink: 1 }}>
                          <Text color={'primary.600'} fontSize={13}>{buttonText}</Text>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                          <Icon style={{ color: '#00aaff' }} name={'angle-right'} size={13} />
                        </Box>
                      </Box>
                    </Button>
                }
                {
                    pending &&
                    <Button colorScheme={'danger'} variant={'subtle'} onPress={() => {}}>
                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box style={{ flexGrow: 1, flexShrink: 1 }}>
                          <Text color={'danger.600'} fontSize={13}><Trans>Pending</Trans></Text>
                        </Box>
                      </Box>
                    </Button>
                }
              </>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default CheckListItem;
