import React, { useState } from 'react';
import { Box } from 'native-base';
import AsyncSelelect from 'react-select/async';
import Trans from '../../Views/Components/Trans';
import { v4 as uuidv4 } from 'uuid';

let waitInterval = null;

const AutoComplete = (props) => {

  const [key, setKey] = useState(uuidv4());

  const onChange = (val) => {
    if (typeof val === 'object' && val !== null) {
      if (typeof props.onSelected === 'function') {
        props.onSelected({
          id: val.value,
          title: val.label
        });
      }
    }

    if ('clearOnSelect' in props) {
      setKey(uuidv4());
    }
  }

  const loadOptions = (input, callback) => {
    //clear interval
    clearInterval(waitInterval);

    //set timeout
    waitInterval = setInterval(() => {
      let newData = [];
      props.onChange(input).then((result) => {
        if (typeof result === 'object' && result !== null) {
          newData = result.map(({ id, title }) => ({
            'value': id,
            'label': title
          }));
        }
        callback(newData);
        clearInterval(waitInterval);
      });
    }, 1000);
  }

  return (
    <Box key={key} style={{ fontFamily: 'Poppins', marginBottom: 10, zIndex: 2 }}>
      <AsyncSelelect
        onChange={onChange}
        loadOptions={loadOptions}
        noOptionsMessage={() => <Trans>Nothing found</Trans>}
        loadingMessage={() => <Trans>Loading...</Trans>}
        isClearable={true}
        placeholder={<Trans>Autocomplete</Trans>}
        components={{
          'DropdownIndicator': () => null,
          'IndicatorSeparator': () => null
        }}
      />
    </Box>
  );
};

export default AutoComplete;