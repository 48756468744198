import APIAction from './APIAction';

const SkillsAction = {
    // Function to add/ remove a skill
    toggleSkill: async (skillId, entityId, action = 'add') => {
        await APIAction.request({method: 'post', url: '/api/skill/toggle', body: {
            skillId: skillId,
            entityId: entityId,
            entityType: 'employee',
            action: action,
        }});
    },
    // Function to loop through parents until we have the parent at lvl 1
    getParent: (skill) => {
        // Loop through parents
        while (skill.lvl !== 1) {
            skill = skill.parent;
        }

        // Return parent
        return skill;
    },
    // Find skills
    findSkills: async (lvl, parent = '', group = '') => {
        return await APIAction.request({method: 'get', url: '/api/find/skills', params: {
            level: lvl,
            group: group,
            parent: parent,
        }});
    },
};

export default SkillsAction;
