import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Stack, Box, Heading, Text, Switch, HStack } from 'native-base';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import APIAction from '../../../Actions/APIAction';

const mainStyle = StyleSheet.create(main);

const PreferenceChecks = (props) => {

    const
        [values, setValues] = useState({
            shareProfile: false,
            addJobsToCalendar: false
        })
        ;

    useEffect(() => {
        if (props.user) {
            setValues({
                shareProfile: props.user.employee.shareProfile,
                addJobsToCalendar: props.user.employee.addJobsToCalendar
            });
        }
    }, [props.user]);

    const changeValue = async (value) => {

        let newValues = ({ ...values, ...value });
        setValues(newValues);

        //make request
        await APIAction.request({
            method: 'PATCH',
            url: `/api/employees/${props.user.employee.id}`,
            body: newValues
        });

        //reload
        if (typeof props.onReload === 'function') {
            props.onReload();
        }
    }

    return (
        <Stack
            space={2}
            w={'100%'}
            direction={{
                base: 'column',
                lg: 'row'
            }}
        >
            <Box style={[mainStyle.boxItem, { marginBottom: 0, flex: 1 }]}>
                <HStack space={2} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                    <Box flexGrow={1} flexShrink={1}>
                        <Heading style={mainStyle.mediumTitle}><Trans>Show my full profile to co-workers</Trans></Heading>
                        <Text style={mainStyle.infoText}><Trans>Your colleagues will be able to see your name, contact details and profile picture.</Trans></Text>
                    </Box>
                    <Switch isDisabled={props.user === null} offTrackColor="#beebe3" onTrackColor="#2ebfa5" onThumbColor="#fff" offThumbColor="#fff" onValueChange={(val) => { changeValue({ shareProfile: val }) }} isChecked={values.shareProfile} />
                </HStack>
            </Box>
            <Box style={[mainStyle.boxItem, { marginBottom: 0, flex: 1 }]}>
                <HStack space={2} justifyContent={'space-between'} alignItems={'center'} flexGrow={1} flexShrink={1}>
                    <Box flexGrow={1} flexShrink={1}>
                        <Heading style={mainStyle.mediumTitle}><Trans>Put jobs into my Calendar</Trans></Heading>
                        <Text style={mainStyle.infoText}><Trans>Skedle will automatically insert and update your job details, location, start and end hours in the Calendar on this mobile device</Trans></Text>
                    </Box>
                    <Switch isDisabled={props.user === null} offTrackColor="#beebe3" onTrackColor="#2ebfa5" onThumbColor="#fff" offThumbColor="#fff" onValueChange={(val) => { changeValue({ addJobsToCalendar: val }) }} isChecked={values.addJobsToCalendar} />
                </HStack>
            </Box>
        </Stack>
    );
};

export default PreferenceChecks;