import React from 'react';
import { Platform } from 'react-native';
import { Box, Image, Spinner } from 'native-base';
import { useRecoilState } from 'recoil';
import LoadingState from '../../Recoil/LoadingState';
import AppContainer from '../Components/AppContainer';
import SettingAction from '../../Actions/SettingAction';
import TranslationAction from '../../Actions/TranslationAction';
import { init } from '../../Libs/Firebase';
import APIAction from '../../Actions/APIAction';
import CalendarAction from '../../Actions/CalendarAction';
import logger from '@nozbe/watermelondb/utils/common/logger';

import { shouldPolyfill as shouldIGCLPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldILPolyfill } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldIDNPolyfill } from '@formatjs/intl-displaynames/should-polyfill';

const logo = require('../../Assets/Images/logo.png');

const pageContent =
    <AppContainer noTabBar noGradient>
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Image source={logo} size={'2xl'} resizeMode={'contain'} />
            <Spinner size={'lg'} color={'#00AAFF'} />
        </Box>
    </AppContainer>;

const warn = () => {
    console.log("%cWarning message:", "font: 2em sans-serif; color: red; background-color: white; font-weight: bold;");
    console.log("%cDo not copy commands into the console, this might compromise your account!", "font: 1.5em sans-serif; color: red; background-color: white;");
};

const LoadingScreenWithState = (props) => {

    let [loaded, setLoaded] = useRecoilState(LoadingState);

    //check connection
    const checkConnection = async () => {
        let requestResult = await APIAction.request({ method: 'GET', url: '/', authenticate: false, catchFail: false });
        if (requestResult === false) {
            let checkAgain = new Promise((resolve) => {
                setTimeout(async () => {
                    let newRequest = await checkConnection();
                    resolve(newRequest);
                }, 5000);
            });

            return await checkAgain;
        }
        return true
    }

    //polyfill loader
    const loadPolyfills = async () => {
        await import('url-search-params-polyfill');
        if (shouldIGCLPolyfill()) {
            await import('@formatjs/intl-getcanonicallocales/polyfill');
        }
        if (shouldILPolyfill()) {
            await import('@formatjs/intl-locale/polyfill');
        }
        if (shouldIDNPolyfill('nl') || shouldIDNPolyfill('fr') || shouldIDNPolyfill('de') || shouldIDNPolyfill('en')) {
            await import('@formatjs/intl-displaynames/polyfill-force');
        }
        if (shouldIDNPolyfill('nl')) {
            await import('@formatjs/intl-displaynames/locale-data/nl');
        }
        if (shouldIDNPolyfill('fr')) {
            await import('@formatjs/intl-displaynames/locale-data/fr');
        }
        if (shouldIDNPolyfill('de')) {
            await import('@formatjs/intl-displaynames/locale-data/de');
        }
        if (shouldIDNPolyfill('en')) {
            await import('@formatjs/intl-displaynames/locale-data/en');
        }

        if (Platform.OS !== 'web') {
            await import('@formatjs/intl-pluralrules/polyfill-force');
            await import('@formatjs/intl-pluralrules/locale-data/nl');
            await import('@formatjs/intl-pluralrules/locale-data/fr');
            await import('@formatjs/intl-pluralrules/locale-data/de');
            await import('@formatjs/intl-pluralrules/locale-data/en');
            await import('@formatjs/intl-numberformat/polyfill-force');
            await import('@formatjs/intl-numberformat/locale-data/nl');
            await import('@formatjs/intl-numberformat/locale-data/fr');
            await import('@formatjs/intl-numberformat/locale-data/de');
            await import('@formatjs/intl-numberformat/locale-data/en');
        }
    }

    //todo before application propper start
    const preStart = async () => {
        //silence
        logger.silence();

        //load firebase
        await SettingAction.rem('FirebaseLoaded');
        await init();

        //check connection
        await checkConnection();

        //load translations
        await TranslationAction.updateIfRequired();

        //fix session based on platform (Require new login when session had ended on browser)
        if (Platform.OS === 'web') {
            let sessionStarted = sessionStorage.getItem('SessionStarted');
            if (null === sessionStarted) await SettingAction.rem('Token');
            sessionStorage.setItem('SessionStarted', '1');
        } else {
            await SettingAction.rem('Token');
        }

        //init calendar
        await CalendarAction.init();

        //load polyfills if required
        await loadPolyfills();

        //set loaded
        setLoaded(true);
    };

    if (('loadingState' in props) && !loaded) {
        preStart();
        warn();
    }

    return (
        pageContent
    );
}

const LoadingScreen = (props) => {
    if ('loadingState' in props) {
        return (
            <LoadingScreenWithState {...props} />
        );
    }
    return (pageContent);
}

export default LoadingScreen;