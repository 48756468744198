import React, { useEffect, useState } from 'react';
import {Box, Button, Text} from 'native-base';
import AppContainer from '../../Components/AppContainer';
import { useIsFocused } from '@react-navigation/native';
import APIAction from '../../../Actions/APIAction';
import Trans from '../../Components/Trans';
import Placeholder from '../../Components/Placeholder';
import { v4 as uuidv4 } from 'uuid';
import JobPreview from '../Preview/Components/JobPreview';
import UserAction from '../../../Actions/UserAction';


const ApproveJob = ({ route, navigation }) => {
    // Init
    let id = '';
    const isFocused = useIsFocused();

    let [jobPreviewElement, setJobPreviewElement] = useState([]);
    let [job, setJob] = useState([]);
    let [user, setUser] = useState([]);

    // Get id
    if (route.params) {
        if (route.params.id) {
            id = route.params.id;
        }
    }

    useEffect(() => {
        if (id) {
            setJobPreviewElement(<Placeholder key={uuidv4()}/>);
        }

        const fetchData = async () => {
            // Init
            let element = [];

            // Get user
            let dbUser = await UserAction.getUser();
            setUser(dbUser);

            if (id) {
                // Get job
                let job = await APIAction.request({ method: 'get', url: '/api/employee_shifts/' + id });
                setJob(job);

                // Get job element
                element.push(<JobPreview  key={uuidv4()} job={job}/>);
                setJobPreviewElement(element);
            }
        };

        fetchData();
    }, [isFocused, id]);


    return (
        <AppContainer>
            {jobPreviewElement}
            <Box>
                <Button colorScheme={'light'} variant={'subtle'} onPress={async () => {
                    // Init
                    let url = '/api/employee_shifts/' + job.id;
                    let data = {
                        approvedBy: '/api/users/' + user.userId,
                        approvedAt: new Date(),
                    };

                    // Update employee shift
                    await APIAction.request({method: 'PATCH', url: url, body: data});

                    // Go back (and refresh) to main page app
                    navigation.replace('Trophy');
                }}>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Box style={{flexGrow: 1, flexShrink: 1}}>
                            <Text color={'primary.600'} fontSize={13}><Trans>Approve</Trans></Text>
                        </Box>
                    </Box>
                </Button>
            </Box>
        </AppContainer>
    );
};

export default ApproveJob;
