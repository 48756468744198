import React from 'react';
import {StyleSheet} from 'react-native';
import {Box, Heading, Text} from 'native-base';
import AppContainer from '../../Components/AppContainer';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import {v4 as uuidv4} from 'uuid';

const mainStyle = StyleSheet.create(main);

const SignDocumentsScreen = () => {

    return (
        <AppContainer noTabBar>
            <>
                <Box key={uuidv4()} style={mainStyle.boxItemVertical}>
                    <Box style={{width: '100%'}}>
                        <Heading style={mainStyle.mediumTitle}><Trans>Before you can start working you'll have to sign some documents</Trans></Heading>
                        <Text style={mainStyle.infoText}>
                            <Trans>We have sent you an email with the documents that you'll have to sign. Please check your mailbox.</Trans>
                        </Text>
                    </Box>
                </Box>
            </>
        </AppContainer>
    );
};

export default SignDocumentsScreen;
