import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Heading, Skeleton, Text, FormControl } from 'native-base';
import AppContainer from '../../Components/AppContainer';
import { useIsFocused } from '@react-navigation/native';
import APIAction from '../../../Actions/APIAction';
import Trans from '../../Components/Trans';
import Placeholder from '../../Components/Placeholder';
import { v4 as uuidv4 } from 'uuid';
import JobPreview from '../Preview/Components/JobPreview';
import GeneralAction from '../../../Actions/GeneralAction';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import DateTimePicker from '../../../Libs/DateTimePicker';
import JobOffer from '../Preview/Components/JobOffer';
import CostForm from './Components/CostForm';
import { NumberInput } from '../../../Libs/CustomInputs';

const mainStyle = StyleSheet.create(main);

const FillInHoursJob = ({ route, navigation }) => {
    // Init
    let id = '';
    const isFocused = useIsFocused();

    let [job, setJob] = useState(null);
    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(new Date());
    let [startHour, setStartHour] = useState(new Date());
    let [endHour, setEndHour] = useState(new Date());
    let [breakDuration, setBreakDuration] = useState(0);

    // Get id
    if (route.params) {
        if (route.params.id) {
            id = route.params.id;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            // Init
            let element = [];

            if (id) {
                // Get job
                let job = await APIAction.request({ method: 'get', url: '/api/employee_shifts/' + id });
                setJob(job);

                // Update default dates
                setStartHour(new Date(job.shift.startOn));
                setEndHour(new Date(job.shift.endOn));
                setStartDate(new Date(job.shift.startOn));
                setEndDate(new Date(job.shift.endOn));

                if (job.breakDuration) {
                    setBreakDuration(job.breakDuration);
                }
            }
        };

        fetchData();
    }, [isFocused, id]);

    /**
     * Save hours of user
     *
     * @returns {Promise<void>}
     */
    let saveHours = async () => {

        // Format hours
        const startOn = new Date(job.shift.startOn);
        let arrivedAt = new Date(startOn.getFullYear(), startOn.getMonth(), startOn.getDate(), startHour.getHours(), startHour.getMinutes());
        let leftAt = new Date(startOn.getFullYear(), startOn.getMonth(), startOn.getDate(), endHour.getHours(), endHour.getMinutes());

        //get offset
        let arrivedAtOffset = GeneralAction.tzOffsetToTz(startHour.getTimezoneOffset());
        let leftAtOffset = GeneralAction.tzOffsetToTz(endHour.getTimezoneOffset());

        //set offset
        arrivedAt = GeneralAction.changeTimezone(arrivedAt, arrivedAtOffset);
        leftAt = GeneralAction.changeTimezone(leftAt, leftAtOffset);

        // Modify left at in case shift ended on the next day (e.g. start at 23h -> end at 05h next day
        while (arrivedAt > leftAt) {
            leftAt = new Date(leftAt.getTime() + 24 * 60 * 60 * 1000);
        }

        /*if (startOn <= arrivedAt) {*/
        if (arrivedAt < leftAt) {
            // Save hours through api
            let url = '/api/employee_shifts/' + job.id;
            let data = {
                arrivedAt: arrivedAt, // @Todo: check if correct
                leftAt: leftAt,
                breakDuration: breakDuration,
                //clientConfirmedAt: null,
                //clientConfirmed: null,
            };

            // Update employee shift
            await APIAction.request({ method: 'PATCH', url: url, body: data });

            // Go back (and refresh) to main page app
            navigation.replace('Trophy');
        } else {
            // Error if start date is after end date
            GeneralAction.toast('error', <Trans>End date cannot be earlier than start date</Trans>);
        }
        /*} else {
          // Error if start date is before shift date
          GeneralAction.toast('error', <Trans>Start date cannot be earlier than the shift date</Trans>);
        }*/

    };


    return (
        <AppContainer>
            <JobPreview job={job} />
            <Box style={mainStyle.boxItemVertical}>
                <Heading style={mainStyle.mediumTitle}><Trans>Hours to approve</Trans></Heading>
                <Divider my={3} style={mainStyle.dividerStyle} />
                <Box style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                    <Box style={{ width: '50%', padding: 10 }}>
                        <Text><Trans>Start</Trans></Text>
                        <DateTimePicker mode={'time'} value={startHour} onChange={(value) => { setStartHour(value); }} />
                    </Box>
                    <Box style={{ width: '50%', padding: 10 }}>
                        <Text><Trans>End</Trans></Text>
                        <DateTimePicker mode={'time'} value={endHour} onChange={(value) => { setEndHour(value); }} />
                    </Box>
                </Box>
                <Box style={{ width: '100%', flexDirection: 'row', paddingTop: 10 }}>
                    <Box style={{ width: '100%', padding: 10 }}>
                        <FormControl>
                            <FormControl.Label><Text><Trans>Break duration (in minutes)</Trans></Text></FormControl.Label>
                            <NumberInput
                                key={'break_duration'}
                                min={0}
                                value={breakDuration}
                                onChange={(val) => {
                                    setBreakDuration(val);
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Button colorScheme={'light'} variant={'subtle'} marginBottom={'10px'} onPress={async () => { await saveHours(); }}>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Box style={{ flexGrow: 1, flexShrink: 1 }}>
                            <Text color={'primary.600'} fontSize={13}><Trans>Confirm</Trans></Text>
                        </Box>
                    </Box>
                </Button>
            </Box>
            {job ?
                <CostForm employeeShift={job} />
                :
                <Skeleton height={'200'} />
            }
        </AppContainer>
    );
};

export default FillInHoursJob;
