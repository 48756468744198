import React from 'react';
import { Center, VStack, Skeleton } from 'native-base';

const Placeholder = ({ }) => {

  return (
    <Center>
      <VStack w={'100%'} borderWidth="1" space={8} overflow="hidden" rounded="md" _dark={{
        borderColor: 'coolGray.500'
      }} _light={{
        borderColor: 'coolGray.200'
      }}>
        <Skeleton h="40" />
      </VStack>
    </Center>
  );
};

export default Placeholder;

