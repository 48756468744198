import React from 'react';
import { ImagePicker } from '../FilePicker';

const CardScanner = (props) => {

    return (
        <ImagePicker
            open={props.isOpen}
            onTrigger={(value) => {
                if (!value) {
                    if (typeof props.onClose === 'function') {
                        props.onClose();
                    }
                }
            }}
            onChange={(file) => {
                props.onPhoto(JSON.parse(JSON.stringify(file)));
                props.onClose();
            }}
        />
    );
};

export default CardScanner;
