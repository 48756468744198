import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Heading, Text } from 'native-base';
import main from '../../../Assets/Styles/main.json';
import AppContainer from '../../Components/AppContainer';
import { useIsFocused } from '@react-navigation/native';
import JobPreview from './Components/JobPreview';
import JobOffer from './Components/JobOffer';
import APIAction from '../../../Actions/APIAction';
import Trans from '../../Components/Trans';
import Placeholder from '../../Components/Placeholder';
import { v4 as uuidv4 } from 'uuid';
import UserAction from "../../../Actions/UserAction";


const mainStyle = StyleSheet.create(main);

const JobPreviewScreen = ({ route, navigation }) => {
  // Init
  let id = '';
  const isFocused = useIsFocused();

  let [jobPreviewElement, setJobPreviewElement] = useState([]);

  // Get id
  if (route.params) {
    if (route.params.id) {
      id = route.params.id;
    }
  }

  useEffect(() => {
    if (id) {
      setJobPreviewElement(<Placeholder key={uuidv4()}/>);
    }
    const fetchData = async () => {
      let element = [];
      let user = await UserAction.getUser();

      if (id) {
        // Get job
        let job = await APIAction.request({ method: 'get', url: '/api/employee_shifts/' + id });
        element.push(<JobPreview key={uuidv4()} job={job}/>);
      } else {
        // Job apply flow uses same view, so for now show a static view @Todo: replace with real data
        if(user && user.employee.payrolling){
          element.push(<JobOffer key={uuidv4()}/>);
        }
      }
      
      setJobPreviewElement(element);

    };

    fetchData();
  }, [isFocused, id]);


  return (
    <AppContainer>
      {/*<Box style={mainStyle.pageTitleWrapper}>
        <Box style={{ flexGrow: 1 }}>
          <Heading style={mainStyle.pageTitle}> <Text><Trans>Vacature</Trans></Text> </Heading>
        </Box>
      </Box> */ }

      {jobPreviewElement}

    </AppContainer>
  );
};

export default JobPreviewScreen;