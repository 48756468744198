import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Stack, Box, Text, Heading, Skeleton, Pressable, Input, HStack } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import Trans from '../../Components/Trans';
import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import FormAction from '../../../Actions/FormAction';
import APIAction from '../../../Actions/APIAction';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ContactInfo = (props) => {

    const
        [editMobile, setEditMobile] = useState(false),
        [phone, setPhone] = useState(null),
        [phoneError, setPhoneError] = useState(null)
        ;

    useEffect(() => {
        if (props.user) {
            setPhone(props.user.phone);
        }
    }, [props.user]);

    const save = async () => {
        if (FormAction.validatePhone(phone)) {
            setPhoneError(null);
            setEditMobile(false);

            //send
            await APIAction.request({
                method: 'PATCH',
                url: `/api/users/${props.user.id}`,
                body: {
                    phone: phone
                }
            });

            //reload
            if (typeof props.onReload === 'function') {
                props.onReload();
            }

        } else {
            setPhoneError(<Trans>Please enter a valid phone number</Trans>);
        }
    }

    return (
        <>
            {props.user ?
                <Stack
                    space={2}
                    w={'100%'}
                    direction={{
                        base: 'column',
                        lg: 'row'
                    }}
                >
                    {props.user.email &&
                        <Box style={[mainStyle.boxItem, { marginBottom: 0, flex: 1 }]}>
                            <Box style={{ alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                                <Box style={{ width: '100%' }}>
                                    <Text style={{ color: 'rgba(0,0,0,0.5)' }}>
                                        <Trans>E-mail</Trans>
                                    </Text>
                                    <Heading style={[mainStyle.mediumTitle, { fontSize: 14 }]}>{props.user.email}</Heading>
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box style={[mainStyle.boxItem, { marginBottom: 0, flex: 1 }]}>
                        <Box style={{ alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                            <Box style={{ width: '100%' }}>
                                <Box style={{ flexDirection: 'row' }}>
                                    <HStack
                                        flexGrow={1}
                                        flexShrink={1}
                                        space={2}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                    >
                                        <Text style={{ color: 'rgba(0,0,0,0.5)' }}>
                                            <Trans>Mobile</Trans>
                                        </Text>
                                        {/*editMobile ?
                                            <Pressable onPress={() => {
                                                save();
                                            }}>
                                                <Icon name={'save'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                            </Pressable>
                                            :
                                            <Pressable onPress={() => {
                                                setEditMobile(true);
                                            }}>
                                                <Icon name={'edit'} size={20} style={{ color: 'rgba(0,0,0,0.5)' }} />
                                            </Pressable>
                                        */}
                                    </HStack>
                                </Box>
                                {
                                    !editMobile &&
                                    <Heading style={[mainStyle.mediumTitle, { fontSize: 14 }]}>
                                        {phone ?
                                            phone :
                                            <Trans>Not added</Trans>
                                        }
                                    </Heading>
                                }
                                {
                                    editMobile &&
                                    <>
                                        <Input w={'100%'} defaultValue={phone} onChangeText={(value) => setPhone(value)} />
                                        {phoneError !== '' && <Text style={main.textError}>{phoneError}</Text>}
                                    </>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Stack> :
                <Stack
                    space={2}
                    w={'100%'}
                    direction={{
                        base: 'column',
                        lg: 'row'
                    }}
                >
                    <Box flex={1}>
                        <Skeleton h={85} />
                    </Box>
                    <Box flex={1}>
                        <Skeleton h={85} />
                    </Box>
                </Stack>
            }
        </>
    );
};

export default ContactInfo;