import { Database } from '@nozbe/watermelondb';
import adapter from './Adapter';

import Language from './Model/Language';
import Translation from './Model/Translation';
import TranslationSync from './Model/TranslationSync';
import Setting from './Model/Setting';
import CurrentUser from './Model/CurrentUser';
import RequestCache from './Model/RequestCache';

// Then, make a Watermelon database from it!
const database = new Database({
    adapter,
    modelClasses: [
        Language,
        Translation,
        TranslationSync,
        Setting,
        CurrentUser,
        RequestCache,
    ],
});

export default database;