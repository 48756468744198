import React from 'react';
import { SafeAreaView } from 'react-native';
import { Alert, VStack, HStack, Text } from 'native-base';

const Toast = (props) => {

    //default status
    let status = 'info';
    if ('status' in props) {
        status = props.status;
    }

    return (
        <SafeAreaView>
            <Alert
                variant={'top-accent'}
                status={status}
            >
                <VStack space={2} flexShrink={1} w="100%">
                    <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                        <HStack space={2} flexShrink={1} alignItems="center">
                            <Alert.Icon />
                            <Text>
                                {props.children}
                            </Text>
                        </HStack>
                    </HStack>
                </VStack>
            </Alert>
        </SafeAreaView>
    );
};

export default Toast;