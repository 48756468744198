import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-react';

const CLottie = (props) => {

    const lottieRef = useRef(null);

    const cProps = {};
    if (props.source) cProps.animationData = props.source;
    if (props.loop === true) cProps.loop = props.loop; else cProps.loop = 0;
    if (props.autoPlay) cProps.autoplay = props.autoPlay;
    if (props.onAnimationFinish) cProps.onComplete = props.onAnimationFinish;
    if (props.style) cProps.style = props.style;

    useEffect(() => {
        if (lottieRef.current && props.speed) {
            lottieRef.current.setSpeed(props.speed);
        }
    }, [props.speed]);

    return (
        <Lottie lottieRef={lottieRef} {...cProps} />
    )
};

export default CLottie;